import axios from "axios";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

// Add New People
export const add_person = async (values: object) => {
  let response = await axios.post(baseURL + "/api/people", values, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  return response.data;
}
// get all people API
export const get_all_people = async (values: object) => {
  let response = await axios.post(baseURL + "/api/get-active-inactive-and-all-peoples", values);
  return response.data;
}
// change status people
export const change_active_status_people = async (id: number) => {
  let response = await axios.post(baseURL + "/api/update-status/" + id, {
    model_name: "people",
  });
  return response.data;
}
// Get People Filter Api
export const get_all_filtered_people = async (subcategory, status) => {
  const response = await axios.post(baseURL + '/api/get-active-inactive-and-all-peoples', {
    sub_category_id: subcategory,
    status: status,
  });
  return response.data;
};
// Delete people
export const delete_people = async (id: number) => {
  let response = await axios.delete(baseURL + "/api/people/" + id);
  return response.data;
}
// get single people
export const get_single_person = async (id: number) => {
  let response = await axios.get(baseURL + "/api/people/" + id);
  return response.data;
}
// get single people
export const get_single_person_data = async (id: number) => {
  let response = await axios.get(baseURL + "/api/get-people-by-id/" + id);
  return response.data;
}
export const update_people = async (updatedPeople) => {
  let id: number;
  id = updatedPeople.id
  let response = await axios.post(baseURL + "/api/update-people/" + id, updatedPeople, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  return response.data;
}
// Get Single person Logs by ID
export const get_people_logs = async (id: number) => {
  let response = await axios.get(baseURL + "/api/people-activity-log/" + id);
  return response.data;
};
// Get All people Logs
export const get_all_people_logs = async () => {
  let response = await axios.get(baseURL + "/api/people-activity-logs");
  return response.data;
};