import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import AllCategoriesLogs from '../pages/categories/components/AllCategoriesLogs'
import CategoryLogs from '../pages/categories/components/CategoryLogs'
import CategoriesList from '../pages/categories/components/CategoriesList'
import AddCategory from '../pages/categories/components/AddCategory'
import AddUser from '../pages/users/components/AddUser'
import UsersList from '../pages/users/components/UsersList'
import AddSubcategory from '../pages/subcategories/components/AddSubcategory'
import SubcategoriesList from '../pages/subcategories/components/SubcategoriesList'
import SubcategoryLogs from '../pages/subcategories/components/SubcategoryLogs'
import AllSubcategoryLogs from '../pages/subcategories/components/AllSubcategoryLogs'
import AddLocation from '../pages/locations/components/AddLocation'
import LocationsList from '../pages/locations/components/LocationsList'
import AllLocationsLogs from '../pages/locations/components/AllLocationsLogs'
import LocationLogs from '../pages/locations/components/LocationLogs'
import AddPeople from '../pages/people/components/AddPeople'
import PeopleList from '../pages/people/components/PeopleList'
import EditPeople from '../pages/people/components/EditPeople'
import ViewPeople from '../pages/people/components/ViewPeople'
import AllPeoplelogs from '../pages/people/components/AllPeoplelogs'
import PeopleLogs from '../pages/people/components/PeopleLogs'
import AllUsersLogs from '../pages/users/components/AllUsersLogs'
import EditUser from '../pages/users/components/EditUser'
import UserLogs from '../pages/users/components/UserLogs'
import ViewUser from '../pages/users/components/ViewUser'
import GeneralSetting from '../pages/generalSettings/components/GeneralSetting'
import AddRole from '../pages/users/roles/components/AddRole'
import EditRole from '../pages/users/roles/components/EditRole'
import RolesList from '../pages/users/roles/components/RolesList'
import AllRolesLogs from '../pages/users/roles/components/AllRolesLogs'
import AppointmentsList from '../pages/appointments/components/AppointmentsList'
import ViewAppointment from '../pages/appointments/components/ViewAppointment'
import AllAppointmentsLogs from '../pages/appointments/components/AllAppointmentsLogs'
import AppointmentLogs from '../pages/appointments/components/AppointmentLogs'
import { permissionCheck } from '../helper-functions/PermissionCheck'
import ProfileUpdate from '../pages/accounts/components/ProfileUpdate'
import AccountSetting from '../pages/accounts/components/AccountSetting'
import AddonSettings from '../pages/addonSettings/components/AddonSettings'
import PeopleAppointments from '../pages/appointments/components/PeopleAppointments'
import { useAuth } from '../modules/auth'
import PeopleProfile from '../pages/accounts/components/PeopleProfile'
import OrganizationSettings from '../pages/organization-settings/components/OrganizationSettings'

var category_create, category_activity_log, category_view
var subcategory_create, subcategory_activity_log, subcategory_view
var location_create, location_activity_log, location_view
var people_create, people_activity_log, people_view, people_edit
var appointment_activity_log, appointment_view
var user_create, user_activity_log, user_view, user_edit
var role_create, role_activity_log, role_view, role_edit
var generalsettings_view
const PrivateRoutes = () => {
  category_create = permissionCheck('category-create')
  category_activity_log = permissionCheck('category-activity-log')
  category_view = permissionCheck('category-view')

  subcategory_create = permissionCheck('subcategory-create')
  subcategory_activity_log = permissionCheck('subcategory-activity-log')
  subcategory_view = permissionCheck('subcategory-view')

  location_create = permissionCheck('location-create')
  location_activity_log = permissionCheck('location-activity-log')
  location_view = permissionCheck('location-view')

  appointment_activity_log = permissionCheck('appointment-activity-log')
  appointment_view = permissionCheck('appointment-view')

  people_create = permissionCheck('people-create')
  people_activity_log = permissionCheck('people-activity-log')
  people_view = permissionCheck('people-view')
  people_edit = permissionCheck('people-update')

  user_create = permissionCheck('user-create')
  user_activity_log = permissionCheck('user-activity-log')
  user_view = permissionCheck('user-view')
  user_edit = permissionCheck('user-update')

  role_create = permissionCheck('role-create')
  role_activity_log = permissionCheck('role-activity-log')
  role_view = permissionCheck('role-view')
  role_edit = permissionCheck ('role-update')

  generalsettings_view = permissionCheck('generatsettings-view')
  const { auth } = useAuth();
  const isPeople = auth?.user?.is_people;
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {category_create && <Route path="/categories/add-category" element={<AddCategory />} />}
        {category_view && <Route path="/categories/categories-list" element={<CategoriesList />} />}
        {category_activity_log && <Route path="/categories/category-logs" element={<CategoryLogs />} />}
        {category_activity_log && <Route path="/categories/all-categories-logs" element={<AllCategoriesLogs />} />}
        {user_create && <Route path="/users/add-user" element={<AddUser />} />}
        {user_view && <Route path="/users/users-list" element={<UsersList />} />}
        {user_activity_log && <Route path="/users/all-users-logs" element={<AllUsersLogs />} />}
        {user_edit && <Route path="/users/edit-user" element={<EditUser />} />}
        {user_view && <Route path="/users/view-user" element={<ViewUser />} />}
        {user_activity_log && <Route path="/users/user-logs" element={<UserLogs />} />}
        {role_create && <Route path="/users/roles/add-role" element={<AddRole />} />}
        {role_edit && <Route path="/users/roles/edit-role" element={<EditRole />} />}
        {role_view && <Route path="/users/roles/roles-list" element={<RolesList />} />}
        {role_activity_log && <Route path="/users/roles/all-roles-logs" element={<AllRolesLogs />} />}
        {subcategory_create && <Route path="/categories/add-subcategory" element={<AddSubcategory />} />}
        {subcategory_view && <Route path="/categories/subcategories-list" element={<SubcategoriesList />} />}
        {subcategory_activity_log && <Route path="/categories/subcategory-logs" element={<SubcategoryLogs />} />}
        {subcategory_activity_log && <Route path="/categories/all-subcategories-logs" element={<AllSubcategoryLogs />} />}
        {location_create && <Route path="/locations/add-location" element={<AddLocation />} />}
        {location_view && <Route path="/locations/locations-list" element={<LocationsList />} />}
        {location_activity_log && <Route path="/locations/all-locations-logs" element={<AllLocationsLogs />} />}
        {location_activity_log && <Route path="/locations/location-logs" element={<LocationLogs />} />}
        {people_create && <Route path="/people/add-person" element={<AddPeople />} />}
        {people_view && <Route path="/people/people-list" element={<PeopleList />} />}
        <Route path="/people/edit-person" element={<EditPeople />} />
        {people_view && <Route path="/people/view-person" element={<ViewPeople />} />}
        {people_activity_log && <Route path="/people/all-people-logs" element={<AllPeoplelogs />} />}
        {people_activity_log && <Route path="/people/person-logs" element={<PeopleLogs />} />}
        {appointment_view && <Route path="/appointments/appointments-list" element={<AppointmentsList />} />}
        {isPeople && <Route path="/appointments/people-appointments-list" element={<PeopleAppointments />} />}
        {appointment_view || isPeople ? <Route path='/appointments/view-appointment' element={<ViewAppointment />} /> : ''}
        {appointment_activity_log || isPeople ? <Route path="/appointments/all-appointments-logs" element={<AllAppointmentsLogs />} />  : ''}
        {appointment_activity_log || isPeople ? <Route path="/appointments/appointment-logs" element={<AppointmentLogs />} /> : ''}
        {generalsettings_view && <Route path="/general/add-general-setting" element={<GeneralSetting />} />}
        {generalsettings_view && <Route path="/add-on-settings" element={<AddonSettings />} />}
        <Route path='/accounts/profile' element={isPeople? <PeopleProfile />: <ProfileUpdate/>} />
        <Route path='/accounts/change-password' element={<AccountSetting/>} />
        <Route path='/organisation/organisation-settings' element={<OrganizationSettings/>} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='dashboard' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
