import { useEffect } from 'react'
import secureLocalStorage from 'react-secure-storage';
import { useAuth } from './core/Auth';
export function Logout() {
  const { auth } = useAuth();
  const isPeople = auth?.user?.is_people;
  useEffect(() => {
    localStorage.removeItem('kt-auth-react-v');
    localStorage.removeItem('kt-auth-react-v-expiration');
    localStorage.removeItem('kt_theme_mode_value');
    secureLocalStorage.removeItem('user_permissions');
    // secureLocalStorage.removeItem('baseURL');
    secureLocalStorage.removeItem('general_settings');
    switch(isPeople){
      case 1: window.location.href = '/auth/people/login';
      break;
      default: window.location.href = '/auth/login';
    }
  }, []);

  return (
    <></>
  );
}
