import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { addLocations, initialValuesLocations as initialValues } from '../../../modules/accounts/components/settings/SettingsModel';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { add_location } from '../api/locationsApi';
import { meetingKeyTypes } from '../../../common/MeetingKeyNames';
const locationSchema = Yup.object().shape({
    type: Yup.string().required("Type is Required"),
    platform_name: Yup.string().required("Name is Required"),
})
function AddLocation() {
    const [loading, setLoading] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const navigate = useNavigate();
    const mutation = useMutation(add_location, {
        onSuccess: (data) => {
            toast.success(data.message, {
                position: "top-right",
                autoClose: 3000,
            });
            navigate('/locations/locations-list');
            setLoading(false);
        },
        onError: (error: any) => {
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 3000,
            });
            setLoading(false);
        }

    });
    const formik = useFormik<addLocations>({
        initialValues,
        validationSchema: locationSchema,
        onSubmit: (values: addLocations) => {
            setLoading(true);
            mutation.mutate({
                type: values.type,
                platform_name: values.platform_name,
                status: values.status,
            });
        },
    });
    // Handle Type Change
    const handleTypeChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedType(selectedValue);
        formik.setFieldValue('type', selectedValue);
    };
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Add Location / Platform</h3>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Type</label>
                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps("type")}
                                    onChange={handleTypeChange}
                                >
                                    <option value=''>Select a Type...</option>
                                    <option value="virtual">{meetingKeyTypes.virtual}</option>
                                    <option value="meeting_at_office">{meetingKeyTypes.meeting_at_office}</option>
                                </select>
                                {formik.touched.type && formik.errors.type && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.type}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                                <span>{selectedType === 'meeting_at_office' ? "Location" : "Platform Name"}</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder={selectedType === 'meeting_at_office' ? "Location" : "Platform name"}
                                    {...formik.getFieldProps('platform_name')}
                                />
                                {formik.touched.platform_name && formik.errors.platform_name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.platform_name}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Status</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps("status")}
                                >
                                    <option value={1}>Active</option>
                                    <option value={0}>InActive</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button data-toggle="tooltip" data-placement="bottom" title={selectedType === 'meeting_at_office' ? 'Add Location' : 'Add Platform'} type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && selectedType === 'meeting_at_office' ? 'Add Location' : 'Add Platform'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default AddLocation;