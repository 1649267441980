import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import ResetSetPassword from '../../pages/users/components/SetResetPassword'
import { PeopleLogin } from './components/PeopleLogin'
import ResetPeoplePassword from './components/ResetPeoplePassword'
import { PeopleForgotPassword } from './components/PeopleForgotPassword'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='people/login' element={<PeopleLogin />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='people-forgot-password' element={<PeopleForgotPassword />} />
      <Route path='reset-password' element={<ResetSetPassword />} />
      <Route path='reset-people-password' element={<ResetPeoplePassword />} />
      <Route path='set-password' element={<ResetSetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
