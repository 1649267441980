import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { KTIcon } from '../../../../_metronic/helpers';
import { KTSVG } from '../../../../_metronic/helpers';
import { formatDateWithTime } from '../../../helper-functions/DateFunction';
import { useNavigate } from 'react-router-dom';
import { change_active_status_user, delete_user, get_all_users_filter } from '../api/usersApi';
import { permissionCheck } from '../../../helper-functions/PermissionCheck';
type User = {
  id: number;
  title: string | undefined;
  picture: string | undefined;
  address: string | undefined;
  email: string | undefined;
  firstname: string | undefined;
  middlename: string | undefined;
  role: string | undefined;
  lastname: string | undefined;
  mobile: string | undefined;
  telephone: string | undefined;
  status: number | undefined;
  created_at: string;
  updated_at: string;
};
var user_create, user_edit, user_delete, user_activity_log, user_view
function UsersList() {
  const [userList, setUserList] = useState<User[]>([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Step 1: State variable for items per page
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null); // State variable for sorting
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState<User | null>();
  const [visibleData, setVisibleData] = useState<User[]>([]);
  user_create = permissionCheck('user-create')
  user_edit = permissionCheck('user-update')
  user_delete = permissionCheck('user-delete')
  user_activity_log = permissionCheck('user-activity-log')
  user_view = permissionCheck('user-view')

  const { data: filterData, isLoading: userIsLoading, isError: userIsError, refetch: filterRefetchUsers } = useQuery(
    'filterData',
    () => get_all_users_filter(selectedStatus),
    {
      refetchOnWindowFocus: false,
      onSuccess: (filteredData) => {
        setUserList(filteredData.data);
      },
      onError: (error: any) => {
        // Error handling
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        }
      },
    }
  );
  useEffect(() => {
    if (filterData) {
      setUserList(filterData.data)
    }
  }, [filterData, selectedStatus]);
  const itemsPerPageOptions = [10, 20, 30, 50, 100]; // Step 2: Options for items per page
  const totalItems =userList.length;
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage); // Update total pages based on selected items per page
  useEffect(() => {
    if (userList.length > 0) {
      let sortedData = [...userList];
      if (sortBy) {
        sortedData.sort((a, b) => {
          if (sortBy && sortBy.key === 'status' && typeof a.status === 'number' && typeof b.status === 'number') {
            return sortBy.ascending ? a.status - b.status : b.status - a.status;
          } else {
            const aValue = (a[sortBy?.key] || '') as string;
            const bValue = (b[sortBy?.key] || '') as string;
            return sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
        });
      }
      setVisibleData(
        sortedData.slice(
          (currentPage - 1) * selectedItemsPerPage, // Update slice based on selected items per page
          currentPage * selectedItemsPerPage
        )
      );
    }
  }, [userList, currentPage, selectedItemsPerPage, sortBy]);
  const handleApplyFilters = () => {
    filterRefetchUsers({ // Enable the query before refetch
      throwOnError: true,
    });
  };
  const handleClearFilters = () => {
    setSelectedStatus('');
    // Refetch the query with the updated selectedStatus
    filterRefetchUsers({
      throwOnError: true,
    }).then(() => {
      filterRefetchUsers();
    }).catch((error: any) => {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 2000,
        });
      }
    });
  };
  const navigate = useNavigate();
  // Handle Delete User
  const handleDeleteUser = useMutation((id: number) => {
    return delete_user(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        filterRefetchUsers();
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage = error.response.data.message
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        };
      });
  });
  // Handle Search
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value.toLowerCase();
    setSearchInput(searchTerm);
    const filteredItems = filterData.data.filter((user: any) => {
      return (
        user.firstname?.toLowerCase().includes(searchTerm) ||
        user.lastname?.toLowerCase().includes(searchTerm) ||
        user.title?.toLowerCase().includes(searchTerm) ||
        user.role?.toLowerCase().includes(searchTerm) ||
        user.middlename?.toLowerCase().includes(searchTerm) ||
        user.email?.toLowerCase().includes(searchTerm) ||
        user.mobile?.toLowerCase().includes(searchTerm) ||
        user.telephone?.toString().includes(searchTerm)
      );
    });
    setUserList(filteredItems);
  };
  // Status Update Users
  const handleStatusUpdate = useMutation((id: number) => {
    return change_active_status_user(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        filterRefetchUsers();
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  const handleSort = (key: string) => {
    if (!sortBy || sortBy.key !== key) {
      // If sortBy is not defined or if sorting a different column, set ascending to true
      setSortBy({ key, ascending: false });
    } else if (key === "updated_by_name") {
     // Toggle between ascending and descending order
     setSortBy({ key, ascending: !sortBy.ascending });
    }else {
      // If sorting the same column, toggle the ascending state
      setSortBy({ key, ascending: !sortBy.ascending });
    }
  };
    const handleNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
      }
    };
    const handlePreviousPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
  const handleUserLogs = (user: any) => {
    navigate("/users/user-logs", { state: { id: user.id } });
  };
  // Edit User
  const handleUserEdit = (user) => {
    navigate("/users/edit-user", { state: { id: user.id } });
  };
  // View User
  const handleUserView = (user) => {
    navigate("/users/view-user", { state: { id: user.id } });
  };
  return (
    <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Users List</span>
        </h3>
        {user_create && (
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <button
              data-toggle="tooltip" data-placement="bottom" title="Navigate to Add Subcategory"
              className='btn btn-sm btn-light-dark'
              onClick={() => navigate('/users/add-user')}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add User
            </button>
          </div>
        )}
      </div>
      <div className='card-header border-0 pt-5 d-flex justify-content-between align-items-center'>
        <h3 className='card-title align-items-start flex-column'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
          <input
            data-toggle="tooltip" data-placement="bottom" title="Search a Product"
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search User'
            value={searchInput}
            onChange={handleInputChange}
          />
        </h3>
        <div className='card-toolbar d-flex align-items-center'>
          <div className='mx-4'>
            <select
              name='type'
              className='form-select form-select-solid form-select-lg fw-bold'
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value=''>Filter by Status...</option>
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-5 mx-3 d-flex flex-column align-items-end'>
        <div>
          <button
            className='btn btn-primary me-3'
            onClick={handleApplyFilters}
          >
            Apply Filters
          </button>
          <button
            className='btn btn-light-danger'
            onClick={handleClearFilters}
          >
            Clear Filters
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
              <th className='min-w-180px'>Picture</th>
                <th className='min-w-180px'>Full Name <span onClick={() => handleSort('fullname')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-180px'>Role <span onClick={() => handleSort('role')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-190px'>Status <span onClick={() => handleSort('status')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-190px'>Updated By <span onClick={() => handleSort('updated_by_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-120px'>Updated At <span onClick={() => handleSort('updated_at')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                {user_activity_log || user_create || user_delete || user_edit || user_view ? (
                  <th className='min-w-100px text-center'>Actions</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {userIsError ? (
                <tr>
                  <td>Error loading data. Please try again later.</td>
                </tr>
              ) : userIsLoading ? (
                <tr>
                  <td colSpan={6}>
                    <div className='text-center'>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : userList && userList.length > 0 ? (
                visibleData?.map((user: any) => (
                  <tr key={user.id}>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 cursor-pointer'>
                          {user.picture ? (
                            <img
                              src={user.picture}
                             width={100}
                              alt='User Pic'
                              className='user-image'
                            />
                          ) : (
                            <div className="symbol symbol-circle symbol-50px">
                              <div className="symbol-label  fs-1 fw-bold text-inverse-dark bg-dark">{user.firstname ? user.firstname[0].toUpperCase() : ''}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                            {user.fullname}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                            {user.role}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>{user.status === 1 ? (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-success'>
                            Active
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-danger'>
                            Inactive
                          </h3>
                        </div>
                      </div>
                    )}
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 cursor-pointer'>
                          {user.updated_by_name}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 cursor-pointer'>
                          {formatDateWithTime(user.updated_at)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        {/* {user_edit && (
                          <button
                            onClick={() => handleUserEdit({ ...user })}
                            data-toggle="tooltip" data-placement="bottom" title="Edit User"
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </button>
                        )} */}
                        {user_view && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="See User Details"
                            onClick={() => handleUserView(user)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='eye' className='fs-3' />
                          </button>
                        )}
                        {user_create && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="update Status"
                            onClick={() => handleStatusUpdate.mutate(user.id)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='update-folder' className='fs-3' />
                          </button>
                        )}
                        {user_activity_log && (
                          <button
                            onClick={() => {
                              handleUserLogs(user);
                            }}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-custom-class='tooltip-dark'
                            title='User Logs'
                          >
                            <i className='bi bi-journal-text'></i>
                          </button>
                        )}
                        {/* {user_delete && (
                          <button
                            onClick={() => setSelectedUser({ ...user })}
                            data-toggle="tooltip" data-placement="bottom" title="Delete User"
                            className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        )} */}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Users Available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-between py-4'>
          <div className='align-items-center row'>
            <div className='d-flex'>
              <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
              <select
                 className='form-select-md'
                value={selectedItemsPerPage}
                onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
              >
                {itemsPerPageOptions.map(option => (
                  <option key={option} value={option} className='p-3 '>{option}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            {currentPage === 1 ? (
              <button
                className='btn btn-light'
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            ) : (
              <button
                className='btn btn-primary'
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            )}
            <button
              className='btn btn-primary ms-2'
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete User Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedUser ? (
                <>
                  <p className='mb-2'>
                    <span className='fw-bold'>FullName: </span>    {selectedUser.title} {selectedUser.firstname}  {selectedUser.middlename}  {selectedUser.lastname}
                  </p>
                  <p className='mb-2'>
                    <span className='fw-bold'>Email: </span>{' '}
                    {selectedUser.email}
                  </p>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                onClick={() => {
                  if (selectedUser) {
                    handleDeleteUser.mutate(selectedUser.id)
                  }
                }}
                data-bs-dismiss='modal'
              >
                Delete User
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default UsersList;