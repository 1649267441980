import axios from "axios";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_all_appointments = async () => {
    let response = await axios.get(baseURL + "/api/appointment")
    return response.data;
};
// Delete Appointment
export const delete_appointment = async (id: number) => {
    let response = await axios.delete(baseURL + "/api/appointment/" + id);
    return response.data;
}
// Delete Appointment
export const delete_people_appointment = async (id: number) => {
    let response = await axios.delete(baseURL + "/api/people-appointment/" + id);
    return response.data;
}
//getting single appointment
export const get_single_appointment = async (id: number) => {
    let response = await axios.get(baseURL + "/api/appointment/" + id);
    return response.data;
}
export const get_single_people_appointment = async (id: number) => {
    let response = await axios.get(baseURL + "/api/people-appointment/" + id);
    return response.data;
}
// Get Single person Logs by ID
export const get_appointment_logs = async (id: number) => {
    let response = await axios.get(baseURL + "/api/single-appointment-log/" + id);
    return response.data;
};
export const get_people_appointment_logs = async (id: number) => {
    let response = await axios.get(baseURL + "/api/people-appointment-log/" + id);
    return response.data;
};
// Get All people Logs
export const get_all_appointments_logs = async () => {
    let response = await axios.get(baseURL + "/api/all-appointment-logs");
    return response.data;
};
export const get_filtered_appointments = async (peopleId, fromDate, toDate, appointmentStatus, paymentStatus) => {
    const response = await axios.post(baseURL + '/api/filtered-appointment', {
        people_flag: peopleId ? "specific" : "",
        people_id: peopleId,
        date_flag: fromDate ? "specific" : "",
        from_date: fromDate,
        to_date: toDate,
        appointment_flag: appointmentStatus ? "specific" : "",
        appointment_status: appointmentStatus,
        payment_flag: paymentStatus ? "specific" : "",
        payment_status: paymentStatus
    });
    return response.data;
};
export const get_appointments_against_person = async (peopleId) => {
    const response = await axios.post(baseURL + '/api/filtered-appointment', {
        people_flag: peopleId ? "specific" : "",
        people_id: peopleId,
        date_flag: "",
        from_date: "",
        to_date: "",
        appointment_flag: "",
        appointment_status: "",
        payment_flag: "",
        payment_status: ""
    });
    return response.data;
};
export const change_appointment_status = async (appointmentId: number, appointmentStatus) => {
    const response = await axios.post(baseURL + '/api/change-appointment-status', {
        appointment_id: appointmentId,
        appointment_status: appointmentStatus,
    });
    return response.data;
};
export const get_all_appointment_notes = async (appointmentId: number) => {
    let response = await axios.post(baseURL + "/api/appointment-notes-against-id", {
        appointment_id: appointmentId,
        appointment_flag: "specific",
        status_flag : "all",
        status: 1
    });
    return response.data;
};
// Delete Appointment Note
export const delete_appointment_note = async (id: number) => {
    let response = await axios.delete(baseURL + "/api/appointment-notes/" + id);
    return response.data;
}
// Update Appointment Notes
export const update_appointemnt_note = async (id: number, appointmentId: number, AppointmentNotes: string) => {
    let response = await axios.patch(baseURL + "/api/appointment-notes/" + id, {
        appointment_id: appointmentId,
        appointment_notes: AppointmentNotes,
        status: 1
    });
    return response.data;
}
// Add Appointment Notes
export const add_appointemnt_note = async (appointmentId: number, AppointmentNotes: string) => {
    let response = await axios.post(baseURL + "/api/appointment-notes/", {
        appointment_id: appointmentId,
        appointment_notes: AppointmentNotes,
        status: 1
    });
    return response.data;
}