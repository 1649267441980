import axios from "axios";
import secureLocalStorage from "react-secure-storage";
const API_URL = process.env.REACT_APP_API_URL;
const API_URL_CENTRAL = process.env.REACT_APP_API_URL_CENTRAL;
const storedData = localStorage.getItem('kt-auth-react-v')
const parsedData = storedData ? JSON.parse(storedData) : null
const api_token = parsedData?.api_token || null

export const config = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: api_token ? `Bearer ${api_token}` : undefined,
  },
}
export const config_multipart = {
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
  },
}
// Get Base Url 
export const getBaseURL = async (current_url) => {
  try {
      const res = await axios.post(`${API_URL}/api/get-base-url`, {
        tenant_domain: current_url
      }, config);

      if (res.status === 200) {
          const current_base_url = res.data.data.base_url;
          secureLocalStorage.setItem('baseURL', current_base_url);
      }
  } catch (error) {
      console.error('Error fetching base URL:', error);
  }
};