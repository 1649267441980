import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { KTIcon, KTSVG } from '../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { allLocationType } from '../../../modules/accounts/components/settings/SettingsModel';
import { change_active_status_location, delete_location, get_all_filters_location, get_all_location, update_location } from '../api/locationsApi';
import { formatDateWithTime } from '../../../helper-functions/DateFunction';
import { meetingKeyTypes } from '../../../common/MeetingKeyNames';
import { permissionCheck } from '../../../helper-functions/PermissionCheck';
import AllUsersLogs from '../../users/components/AllUsersLogs';
var location_create, location_edit, location_delete, location_activity_log

function LocationsList() {
  const [searchInput, setSearchInput] = useState('');
  const [nameValidationError, setNameValidationError] = useState<string | null>(null);
  const [typeValidationError, setTypeValidationError] = useState<string | null>(null)
  const [selectedLocation, setSelectedLocation] = useState<allLocationType | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Step 1: State variable for items per page
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null); // State variable for sorting
  const [visibleData, setVisibleData] = useState<allLocationType[]>([]);
  const [locationList, setLocationList] = useState<allLocationType[]>([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedType, setSelectedType] = useState('');
  location_create = permissionCheck('location-create')
  location_edit = permissionCheck('location-update')
  location_delete = permissionCheck('location-delete')
  location_activity_log = permissionCheck('location-activity-log')
  const {
    data: allLocations,
    isLoading: isAllLocationLoading,
    isError: isAllLocationError,
    refetch
  } = useQuery('locations', get_all_location, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (allLocations) {
      setLocationList(allLocations.data)
    }
  }, [allLocations]);
  // Handle Filter Location
  const { data: filterData, refetch: filterRefetchLocation } = useQuery(
    'filterData',
    () => get_all_filters_location(selectedType, selectedStatus),
    {
      refetchOnWindowFocus: false,
      onSuccess: (filteredData) => {
        setLocationList(filteredData.data);
      },
      onError: (error: any) => {
        // Error handling
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        }
      },
      enabled: false, // Set initial enable state to false
    }
  );
  const handleApplyFilters = () => {
    filterRefetchLocation({ // Enable the query before refetch
      throwOnError: true,
    });
  };
  const handleClearFilters = () => {
    setSelectedType('');
    setSelectedStatus('');
    // Reset to the original location list
    if (allLocations) {
      setLocationList(allLocations.data)
    }
  };
  const navigate = useNavigate();
  const itemsPerPageOptions = [10, 20, 30, 50, 100]; // Step 2: Options for items per page
  const totalItems = locationList.length;
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage); // Update total pages based on selected items per page
  useEffect(() => {
    if (locationList.length > 0) {
      let sortedData = [...locationList];
      if (sortBy) {
        sortedData.sort((a, b) => {
          if (sortBy.key === 'status' && typeof a.status === 'number' && typeof b.status === 'number') {
            return sortBy.ascending ? a.status - b.status : b.status - a.status;
          } else {
            const aValue = (a[sortBy.key] || '') as string;
            const bValue = (b[sortBy.key] || '') as string;
            return sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
        });
      }
      setVisibleData(
        sortedData.slice(
          (currentPage - 1) * selectedItemsPerPage,
          currentPage * selectedItemsPerPage
        )
      );
    }
  }, [locationList, currentPage, selectedItemsPerPage, sortBy]);
  // Search For Location
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value;
    setSearchInput(searchTerm);
    const filteredItems = allLocations.data.filter((location) => {
      return location.type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        location.platform_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        location.updated_at?.toLowerCase().includes(searchTerm.toLowerCase()
        );
    });
    setLocationList(filteredItems);
  }
  // Delete Location
  const handleDeleteLocation = useMutation((id: number) => {
    return delete_location(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        })
        refetch();
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage = error.response.data.message
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        };
      });
  });
  // Status Update Location
  const handleStatusUpdate = useMutation((id: number) => {
    return change_active_status_location(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        refetch();
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  // Reset Validation Error
  const resetValidationErrors = () => {
    setTypeValidationError("");
    setNameValidationError("");
  }
  // Update Location
  const handleUpdateLocation = useMutation((updatedLocationData: { id: number; type: string; platform: string; status: number }) => {
    return update_location(updatedLocationData.id, updatedLocationData.type, updatedLocationData.platform, updatedLocationData.status)
      .then((data) => {
        toast.success(data.message, {
          position: 'top-right',
          autoClose: 3000,
        })
        refetch();
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: 'top-right',
          autoClose: 3000,
        })
      });
  });
  const handleSort = (key: string) => {
    if (!sortBy || sortBy.key !== key || sortBy.ascending) {
      setSortBy({ key, ascending: false }); // Sort in descending order initially
    } else {
      // Toggle between ascending and descending order
      setSortBy({ key, ascending: !sortBy.ascending });
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // Get Single Location Logs
  const handleSingleLocationLogs = (location) => {
    navigate("/locations/location-logs", { state: { id: location.id } });
  };
  return (
    <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>List of Locations / Platforms</span>
        </h3>
        {location_create && (
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <button
              data-toggle="tooltip" data-placement="bottom" title="Add Location"
              className='btn btn-sm btn-light-dark'
              onClick={() => navigate('/locations/add-location')}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add Location / Platform
            </button>
          </div>
        )}
      </div>
      <div className='card-header border-0 pt-5 d-flex justify-content-between align-items-center'>
        <h3 className='card-title align-items-start flex-column'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
          <input
            data-toggle="tooltip" data-placement="bottom" title="Search a location"
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search location'
            value={searchInput}
            onChange={handleInputChange}
          />
        </h3>
        <div className='card-toolbar d-flex align-items-center'>
          <div className='mx-4'>
            <select
              className='form-select form-select-solid form-select-lg fw-bold'
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option value=''>Select a Type...</option>
              <option value="virtual">{meetingKeyTypes.virtual}</option>
              <option value="meeting_at_office">{meetingKeyTypes.meeting_at_office}</option>
            </select>
          </div>
          <div className='mx-4'>
            <select
              name='type'
              className='form-select form-select-solid form-select-lg fw-bold'
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value=''>Filter by Status...</option>
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-5 mx-3 d-flex flex-column align-items-end'>
        <div>
          <button
            className='btn btn-primary me-3'
            onClick={handleApplyFilters}
          >
            Apply Filters
          </button>
          <button
            className='btn btn-light-danger'
            onClick={handleClearFilters}
          >
            Clear Filters
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* Headings of columns in table  */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>
                  Type
                  <span onClick={() => handleSort('type')}>
                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                  </span>
                </th>
                <th className='min-w-140px'>
                  Locations / Platforms
                  <span onClick={() => handleSort('platform_name')}>
                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                  </span>
                </th>
                <th className='min-w-100px'>
                  Status
                  <span onClick={() => handleSort('status')}>
                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                  </span>
                </th>
                <th className='min-w-100px'>
                  Updated By
                  <span onClick={() => handleSort('updated_by')}>
                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                  </span>
                </th>
                <th className='min-w-150px'>
                  Updated At
                  <span onClick={() => handleSort('updated_at')}>
                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                  </span>
                </th>
                {location_activity_log || location_delete || location_edit || location_create ? (
                  <th className='min-w-100px text-center'>Actions</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {isAllLocationError ? (
                <tr>
                  <td>Error loading data. Please try again later.</td>
                </tr>
              ) : isAllLocationLoading ? (
                <tr>
                  <td colSpan={6}>
                    <div className='text-center'>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : locationList.length > 0 ? (
                visibleData.map((location) => (
                  <tr key={location.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {location.type === "virtual" ? (

                            <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                              {meetingKeyTypes.virtual}
                            </h3>
                          ) : (
                            <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                              {meetingKeyTypes.meeting_at_office}
                            </h3>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                            {location.platform_name}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>{location.status === 1 ? (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-success'>
                            Active
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-danger'>Inactive</h3>
                        </div>
                      </div>
                    )}
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start cursor-pointer'>
                          {location.updated_by}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start cursor-pointer'>
                          {formatDateWithTime(location.updated_at)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        {location_edit && (
                          <button
                          data-toggle="tooltip" data-placement="bottom" title="Edit location"
                          className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_2'
                          onClick={() => {
                            setSelectedLocation({ ...location });
                            resetValidationErrors();
                          }}
                        >
                          <KTIcon iconName='pencil' className='fs-3' />
                        </button>
                        )}
                        {location_activity_log && (
                          <button
                          onClick={() => {
                            handleSingleLocationLogs(location);
                          }}
                          className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          data-bs-custom-class='tooltip-dark'
                          title='Location Logs'
                        >
                          <i className='bi bi-journal-text'></i>
                        </button>
                        )}
                        {location_create && (
                           <button
                           data-toggle="tooltip" data-placement="bottom" title="Update Status"
                           onClick={() => handleStatusUpdate.mutate(location.id)}
                           className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                         >
                           <KTIcon iconName='update-folder' className='fs-3' />
                         </button>
                        )}
                        {location_delete && (
                          <button
                          data-toggle="tooltip" data-placement="bottom" title="Delete location"
                          className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_1'
                          onClick={() => {
                            setSelectedLocation({ ...location })
                          }}
                        >
                          <KTIcon iconName='trash' className='fs-3' />
                        </button>
                      )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Locations Available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Location</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedLocation ? (
                <>
                  <div className='mb-2 row mb-6'>
                    <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>
                      Type
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        value={selectedLocation.type}
                        className='form-control form-control-lg form-control-solid'
                        onChange={(e) => {
                          if (!e.target.value) {
                            setTypeValidationError('Type is required.')
                          } else {
                            setTypeValidationError('')
                          }
                          setSelectedLocation({
                            ...selectedLocation,
                            type: e.target.value,
                          })
                        }}
                      >
                        <option value=''>Select a Type...</option>
                        <option value="virtual">{meetingKeyTypes.virtual}</option>
                        <option value="meeting_at_office">{meetingKeyTypes.meeting_at_office}</option>
                      </select>
                      {typeValidationError && (
                        <div className='text-danger'>{typeValidationError}</div>
                      )}
                    </div>
                  </div>
                  <div className='mb-2 row mb-6'>
                    <label className='col-lg-4 ml-4 col-form-label required fw-bold fs-6'>
                      Platform
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        value={selectedLocation.platform_name}
                        className='form-control form-control-lg form-control-solid'
                        onChange={(e) => {
                          if (!e.target.value) {
                            setNameValidationError('Platform Name is required.')
                          } else {
                            setNameValidationError('')
                          }
                          setSelectedLocation({
                            ...selectedLocation,
                            platform_name: e.target.value,
                          })
                        }}
                      />
                      {nameValidationError && (
                        <div className='text-danger'>{nameValidationError}</div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span>Status:</span>
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-select form-select-solid form-select-lg fw-bold'
                        value={selectedLocation.status}
                        onChange={(e) => {
                          setSelectedLocation({
                            ...selectedLocation,
                            status: Number(e.target.value),
                          })
                        }}
                      >
                        <option value={1}>Active</option>
                        <option value={0}>Inactive</option>
                      </select>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              {selectedLocation?.type === "" || selectedLocation?.platform_name === "" ? (
                <>
                  <button type='button' className='btn btn-light-dark' disabled={true}>
                    Update Location
                  </button>
                </>
              ) : (
                <>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={() => {
                      if (selectedLocation && selectedLocation.type && selectedLocation.platform_name) {
                        handleUpdateLocation.mutateAsync({
                          id: selectedLocation.id,
                          type: selectedLocation.type,
                          platform: selectedLocation.platform_name,
                          status: selectedLocation.status,
                        })
                      }
                    }}
                    data-bs-dismiss='modal'
                  >
                    Update Location
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Delete modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete location Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedLocation ? (
                <>
                  <p className='mb-2'>
                    <span className='fw-bold p-3'>Type:</span>{selectedLocation.type}
                  </p>
                  <p className='mb-2'>
                    <span className='fw-bold p-3'>Platform:</span> {selectedLocation.platform_name}
                  </p>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button data-toggle="tooltip" data-placement="bottom" title="Close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                data-toggle="tooltip" data-placement="bottom" title="Delete this Location"
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() => {
                  if (selectedLocation) {
                    handleDeleteLocation.mutate(selectedLocation.id)
                  }
                }}
              >
                Delete Location
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-between py-4'>
        <div className='align-items-center row'>
          <div className='d-flex'>
            <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
            <select
              className='form-select-md'
              value={selectedItemsPerPage}
              onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
            >
              {itemsPerPageOptions.map(option => (
                <option key={option} value={option} className='p-3 '>{option}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          {currentPage === 1 ? (
            <button
              className='btn btn-light'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          ) : (
            <button
              className='btn btn-primary'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          )}
          <button
            className='btn btn-primary ms-2'
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
export default LocationsList;