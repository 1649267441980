import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { addGeneralSettings, initialValuesGeneralSettings as initialValues } from '../../../modules/accounts/components/settings/SettingsModel';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { add_general_setting } from '../api/generalSettingsApi';
const generalSettingSchema = Yup.object().shape({
  category_title: Yup.string().required("Category is Required"),
  subcategory_title: Yup.string().required("SubCategory is Required"),
})
function GeneralSetting() {
  const [loading, setLoading] = useState(false);
  const mutation = useMutation(add_general_setting, {
    onSuccess: (data) => {
        toast.success(data.message, {
            position: "top-right",
            autoClose: 3000,
        });
        setLoading(false);
    },
    onError: (error: any) => {
        toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
        });
        setLoading(false);
    }

});
const formik = useFormik<addGeneralSettings>({
    initialValues,
    validationSchema:generalSettingSchema,
    onSubmit: (values: addGeneralSettings,action) => {
        setLoading(true);
        mutation.mutate({
          category_title: values.category_title,
          subcategory_title: values.subcategory_title,
          theme: values.theme,
        });
        action.resetForm();
    },
});
  return (
    <div className='card mb-5 mb-xl-10'>
    <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
    >
        <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>General Settings</h3>
        </div>
    </div>
    <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
            <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                        <span>Category Title</span>
                    </label>
                    <div className='col-lg-8 fv-row'>
                        <input
                            className='form-control form-control-lg form-control-solid'
                            placeholder=""
                            {...formik.getFieldProps('category_title')}
                        />
                        {formik.touched.category_title && formik.errors.category_title && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.category_title}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                        <span>SubCategory Title</span>
                    </label>
                    <div className='col-lg-8 fv-row'>
                        <input
                            className='form-control form-control-lg form-control-solid'
                            placeholder=""
                            {...formik.getFieldProps('subcategory_title')}
                        />
                        {formik.touched.subcategory_title && formik.errors.subcategory_title&& (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.subcategory_title}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span>Theme</span>
                    </label>
                    <div className='col-lg-8 fv-row'>
                        <select
                            className='form-select form-select-solid form-select-lg fw-bold'
                            {...formik.getFieldProps("theme")}
                        >
                            <option value="Dark">Dark</option>
                            <option value="Light">Light</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button data-toggle="tooltip" data-placement="bottom" title="Add General Settings" type='submit' className='btn btn-primary' disabled={loading}>
                    {!loading && "Add General Settings"}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
        </form>
    </div>
</div>
  )
}

export default GeneralSetting