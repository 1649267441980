import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query';
import { useAuth } from '../../../modules/auth';
import { toast } from 'react-toastify';
import { get_single_people_profile, get_single_user_profile, update_user_profile } from '../api/accountsApi';
import { useNavigate } from 'react-router-dom';
type User = {
    id: number;
    department_id: string;
    department_name: string | undefined;
    title: string | undefined;
    firstname: string | undefined;
    middlename: string | undefined;
    lastname: string | undefined;
    telephone: string | undefined;
    email: string | undefined;
    password: string | undefined;
    mobile: string | undefined;
    picture: string | undefined;
    role: string | undefined;
    password_confirmation: string | undefined;
    status: number | undefined;
    created_at: string;
    deleted_at: string;
    updated_at: string;
};
function ProfileUpdate() {
    const [currentUser, setCurrentUser] = useState<User | null>();
    const [userPicture, setUserPicture] = useState<File | null>(null);
    const [emailError, setEmailError] = useState("");
    const ref = useRef<HTMLInputElement>(null);
    const [firstNameError, setFirstNameError] = useState('');
    const [titleError, setTitleError] = useState('');
    const [telephoneValidationError, setTelephoneValidationError] = useState('');
    const [mobileValidationError, setMobileValidationError] = useState('');
    const [loading, setLoading] = useState(false);
    const { auth } = useAuth();
    const navigate=useNavigate();
    const isPeople = auth?.user?.is_people;
    const { data: UserProfileData, isLoading, error, refetch } = useQuery('ProfileData', isPeople? get_single_people_profile : get_single_user_profile, {refetchOnWindowFocus: false});
    useEffect(() => {
        if (UserProfileData) {
            setCurrentUser(UserProfileData?.data[0]);
        }
    }, [UserProfileData]);
    const handleMidddleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (currentUser) {
            const middleName = e.target.value;
            setCurrentUser({
                ...currentUser,
                middlename: middleName,
            });
        };
    };
    const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (currentUser) {
            const newFirstName = e.target.value;
            if (!newFirstName) {
                setFirstNameError('FirstName is required.');
            } else {
                setFirstNameError('');
            };
            setCurrentUser({
                ...currentUser,
                firstname: newFirstName,
            });
        };
    };
    const handleTitleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (currentUser) {
            const newTitleName = e.target.value;
            if (!newTitleName) {
                setTitleError('Title is required.');
            } else {
                setTitleError('');
            };
            setCurrentUser({
                ...currentUser,
                title: newTitleName,
            });
        };
    };
    const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (currentUser) {
            const lastName = e.target.value;
            setCurrentUser({
                ...currentUser,
                lastname: lastName,
            });
        };
    };
    const handleTelephoneChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (currentUser) {
            const newUserTelephone = e.target.value;
            setCurrentUser({
                ...currentUser,
                telephone: newUserTelephone,
            });
            if (newUserTelephone) {
                const numberRegex = /^[+0-9]+$/;
                if (!numberRegex.test(newUserTelephone)) {
                    setTelephoneValidationError('Telephone Number must be in numeric value.');
                } else {
                    setTelephoneValidationError('');
                };
            };
        }
    };
    const handleUserMobileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (currentUser) {
            const newUserMobile = e.target.value.trim();
            setCurrentUser({
                ...currentUser,
                mobile: newUserMobile,
            });
            if (!newUserMobile) {
                setMobileValidationError('Mobile Number is required.');
            }
            else if (newUserMobile) {
                const numberRegex = /^[+0-9]+$/;
                if (!numberRegex.test(newUserMobile)) {
                    setMobileValidationError('Mobile Number must be in numeric value.');
                } else {
                    setMobileValidationError('');
                };
            };
        };
    };
    const handleUserImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (currentUser) {
            const newPicture = e.target.files?.[0] || null;
            setCurrentUser({
                ...currentUser,
                picture: newPicture ? URL.createObjectURL(newPicture) : undefined,
            });
            setUserPicture(newPicture);
        };
    };
    const handleUserEmail = (e: ChangeEvent<HTMLInputElement>) => {
        if (currentUser) {
            const email = e.target.value.trim();
            if (!email) {
                setEmailError('Email is required.'); // Set error message if email is empty
            } else if (!/\S+@\S+\.\S+/.test(email)) {
                setEmailError('Please enter a valid email address.'); // Set error message for invalid email format
            } else {
                setEmailError('');
            };
            setCurrentUser({
                ...currentUser,
                email: email,
            });
        };
    };
    const handleUpdateUser = () => {
        try {
            setLoading(true);
            if (!currentUser) {
                throw new Error('No User Selected for Update');
            }
            const {
                id,
                department_id,
                title,
                firstname,
                middlename,
                lastname,
                telephone,
                email,
                password,
                mobile,
                picture,
                role,
                password_confirmation,
                status
            } = currentUser;
            const formData: any = new FormData();
            formData.append('id', id);
            formData.append('department_id', department_id || '');
            formData.append('title', title || '');
            formData.append('firstname', firstname || '');
            formData.append('middlename', middlename || '');
            formData.append('lastname', lastname || '');
            formData.append('telephone', telephone || '');
            formData.append('email', email || '');
            formData.append('password', password || '');
            formData.append('mobile', mobile || '');
            formData.append('status', status);
            if (userPicture) {
                formData.append('picture', userPicture);
            }
            formData.append('role', role || '');
            updateUserMutation.mutate({
                id,
                formData,
            });
        } catch (error: any) {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: 'top-right',
                    autoClose: 3000,
                });
            }
            setLoading(false);
        }
    };
    const updateUserMutation = useMutation((Data: any) => update_user_profile(Data.id, Data.formData), {
        onSuccess: (response) => {
            refetch();
            setLoading(false);
            toast.success(response.message, {
                position: 'top-right',
                autoClose: 3000,
            });
            ref.current && (ref.current.value = '')
        },
        onError: (error: any) => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 3000,
                });
                setLoading(false);
            } else if (error.request) {
                // The request was made but no response was received
                toast.error('No response received from the server.', {
                    position: "top-right",
                    autoClose: 3000,
                });
                setLoading(false);
            } else {
                toast.error('An unexpected error occurred.', {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
            setLoading(false);
        },
    });
    if (isLoading) {
        return (
            <div className='text-center'>
                <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            </div>
        )
    }
    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 '
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Profile Details</h3>
                </div>
                <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
        >
          <button
            data-toggle="tooltip" data-placement="bottom" title="Navigate to Dashboard"
            className='btn btn-sm btn-light-dark fs-5'
            onClick={() => navigate('/dashboard')}
          >
            Back to Dashboard
          </button>
        </div>
            </div>
            <div id='kt_account_profile_details'>
                <form noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                Role
                            </label>
                            <div className='col-lg-8'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <input className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            value={currentUser?.role
                                                ? currentUser?.role
                                                : "Role is not assigned yet"}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Title</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    value={currentUser ? currentUser?.title : ''}
                                    onChange={(e) => handleTitleChange(e)}
                                >
                                    <option value=''>Select a Title...</option>
                                    <option value='Mr.'>Mr.</option>
                                    <option value='Mrs.'>Mrs.</option>
                                    <option value='Ms.'>Ms.</option>
                                </select>
                                {titleError && (
                                    <div className='text-danger'>{titleError}</div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                <span>First Name</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='First Name'
                                    value={currentUser ? currentUser?.firstname : ''}
                                    onChange={(e) => handleFirstNameChange(e)}
                                />
                                {firstNameError && (
                                    <div className='text-danger'>{firstNameError}</div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Middle Name</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Middle Name'
                                    value={currentUser ? currentUser.middlename : ''}
                                    onChange={(e) => handleMidddleNameChange(e)}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Last Name</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Last Name'
                                    value={currentUser ? currentUser.lastname : ''}
                                    onChange={(e) => handleLastNameChange(e)}
                                />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Email</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Email'
                                    value={currentUser ? currentUser.email : ''}
                                    onChange={(e) => handleUserEmail(e)}
                                />
                                {emailError && (
                                    <div className='text-danger'>{emailError}</div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Telephone</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Telephone'
                                    value={currentUser ? currentUser?.telephone : ''}
                                    onChange={(e) => handleTelephoneChange(e)}
                                />
                                {telephoneValidationError && (
                                    <div className='text-danger'>{telephoneValidationError}</div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Mobile Number</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Mobile number'
                                    value={currentUser ? currentUser?.mobile : ''}
                                    onChange={(e) => handleUserMobileChange(e)}
                                />
                                {mobileValidationError && (
                                    <div className='text-danger'>{mobileValidationError}</div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Picture</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='file'
                                    className='form-control form-control-lg form-control-solid'
                                    onChange={(e) => handleUserImageChange(e)}
                                    name='picture'
                                />
                                <div className='text-danger mt-2'>
                                    Recommended size 200 x 200 px. jpg, jpeg, or png only
                                </div>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                Existing Picture
                            </label>
                            <div
                                className='col-lg-8 fv-row'
                            >
                                {currentUser?.picture ? (
                                    <img
                                        src={currentUser.picture}
                                        alt='profile picture'
                                        className='img-fluid cover'
                                        width={100}
                                        height={100}
                                        aria-readonly
                                    />
                                ) : (
                                    <span>No picture uploaded yet</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        {firstNameError || titleError || telephoneValidationError || mobileValidationError || emailError ? (
                            <button type='button' data-toggle="tooltip" data-placement="bottom" title="Update User Profile" className='btn btn-primary disabled'>
                                Update User Profile
                            </button>
                        ) : (
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Update User Profile"
                                type='button'
                                className='btn btn-primary'
                                onClick={handleUpdateUser}
                                disabled={loading}
                            >
                                {!loading && 'Update User Profile'}
                                {loading && (
                                    <span className='indicator-progress d-block'>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ProfileUpdate;