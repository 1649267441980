/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../helpers'
import { Appointment } from '../../../../app/pages/appointments/components/AppointmentsList'
import { allLocationType } from '../../../../app/modules/accounts/components/settings/SettingsModel'
import { useQuery } from 'react-query'
import { get_all_location } from '../../../../app/pages/locations/api/locationsApi'
import { Form } from 'react-bootstrap'
import { get_appoitnments_by_locations } from '../../../../app/pages/dashboard/DashboardApi'
import { formatDateWithTime } from '../../../../app/helper-functions/DateFunction'
import { useNavigate } from 'react-router-dom'

type Props = {
  className: string
}

const TablesWidget2: React.FC<Props> = ({ className }) => {
  const [appointmentsByLocation, setAppointmentsByLocation] = useState<Appointment[]>([])
  const [locationsList, setLocationsList] = useState<allLocationType[]>([])
  const [selecetedLocation, setSelectedLocation] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentItems, setCurrentItems] = useState<Appointment[]>([]);
  const navigate = useNavigate()
  const { data: locationsData } = useQuery('locations', get_all_location)
  useEffect(() => {
    if (locationsData) {
      setLocationsList(locationsData.data as allLocationType[])
    }
  }, [locationsData])
  const { data: appointmentsData, isError: appointmentsDataError } = useQuery(['appointments', selecetedLocation], () => get_appoitnments_by_locations(selecetedLocation))
  useEffect(() => {
    if (appointmentsData) {
      setAppointmentsByLocation(appointmentsData.data as Appointment[])
    } else {
      setAppointmentsByLocation([])
    }
  }, [appointmentsData])
  const indexOfLastLog = currentPage * itemsPerPage;
  const indexOfFirstLog = indexOfLastLog - itemsPerPage;
  useEffect(() => {
    if (appointmentsByLocation.length > 0) {
      setCurrentItems(
        appointmentsByLocation.slice(indexOfFirstLog, indexOfLastLog)
      )
    } else {
      setCurrentItems([])
    }
  });
  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(appointmentsByLocation.length / itemsPerPage);
  const getPageNumbers = (): Array<number | string> => {
    const pageNumbers: Array<number | string> = [];
    const totalPageDisplay = 5;
    let startPage = Math.max(1, currentPage - Math.floor(totalPageDisplay / 2));
    let endPage = startPage + totalPageDisplay - 1;
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - totalPageDisplay + 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    if (startPage > 1) {
      pageNumbers.unshift('...');
    }
    if (endPage < totalPages) {
      pageNumbers.push('...');
    }
    return pageNumbers;
  };
  const handleAppointmentView = (appointment) => {
    navigate("/appointments/view-appointment", { state: { id: appointment.id } });
  };
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Appointments</span>
          {appointmentsByLocation.length > 0 ? (
            <span className='text-muted mt-1 fw-semibold fs-7'>Around {appointmentsByLocation.length} Appointments at this location/platform</span>
          ) : (
            <span className='text-muted mt-1 fw-semibold fs-7'>Select a location/platform first</span>
          )}
        </h3>
        <div className='card-toolbar'>
          <Form.Select
            onChange={(e) => setSelectedLocation(e.target.value)}
          >
            <option>Select a Location/Platform</option>
            {Array.isArray(locationsList) && locationsList.length > 0 ? (
              locationsList.map((locationsList) => (
                <option key={locationsList.id} value={locationsList.id}>
                  {locationsList.platform_name}
                </option>
              ))
            ) : (
              <option value='' disabled>No Locations/Platform Available</option>
            )}
          </Form.Select>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='p-0 min-w-150px'></th>
                <th className='p-0 min-w-150px'></th>
                <th className='p-0 min-w-150px'></th>
                <th className='p-0 min-w-125px'></th>
                <th className='p-0 min-w-40px'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            {currentItems.length <= 0 ? (
              <div>No Appointments found</div>
            ) : (
              <tbody>
                {currentItems.map((appointment, index) => (
                  <tr key={index}>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary mb-1 fs-6 d-block'>
                        {appointment.full_name}
                      </a>
                      {appointment.appointment_date_time_slots.map((dateTime) => <span className='text-muted fw-semibold fs-8'>{formatDateWithTime(dateTime.datetime)}, </span>)}
                    </td>
                    <td>
                      <a className='text-dark fw-bold text-hover-primary mb-1 fs-6 d-block'>
                        {appointment.people_name}
                      </a>
                    </td>
                    <td className='text-end text-muted fw-semibold'>{appointment.subcategory_title}</td>
                    <td className='text-end'>
                      {appointment.appointment_status == 'open' ? (
                        <span className='badge badge-light-success'>{appointment.appointment_status}</span>
                      ) : (
                        <span className='badge badge-light-danger'>{appointment.appointment_status}</span>
                      )}
                    </td>
                    <td className='text-end'>
                      <a
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                        onClick={() => handleAppointmentView(appointment)}
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
            {/* end::Table body */}
          </table>
          {appointmentsByLocation.length > 5 && (

            <div className="mb-3 d-flex justify-content-around align-items-center">

              <ul className="pagination my-5">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                {getPageNumbers().map((pageNumber, index) => (
                  <li
                    key={index}
                    className={`page-item ${currentPage === pageNumber ? 'active' : ''
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => {
                        if (typeof pageNumber === 'number') {
                          handlePageChange(pageNumber);
                        }
                      }}
                    >
                      {pageNumber}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? 'disabled' : ''
                    }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          )}
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { TablesWidget2 }
