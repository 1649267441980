import axios from "axios";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_all_permissions = async () => {
    let response = await axios.get(baseURL + '/api/permissions')
    return response.data
};
// Add New Role
export const create_role = async (name: string,organization_id:number, status: number, permissions: string[]) => {
    const values = {
        name,
        status,
        organization_id,
        permissions,
    }
    let response = await axios.post(baseURL + '/api/roles', values)
    return response.data
};
// Get All Roles
export const get_all_roles = async () => {
    let response = await axios.get(baseURL + "/api/roles");
    return response.data;
};
// Delete Role
export const delete_role = async (id: number) => {
    let response = await axios.delete(baseURL + "/api/roles/" + id);
    return response.data;
};
// change status
export const change_active_status_role = async (id: number) => {
    let response = await axios.post(baseURL + "/api/update-status/" + id, {
        model_name: "role",
    });
    return response.data;
};
// Get All Roles Logs
export const get_all_roles_logs = async () => {
    let response = await axios.get(baseURL + "/api/all-roles-logs");
    return response.data;
};
// Edit Role
export const edit_role = async (id: number, name: string, permissions: string[], status:number,organization_id:number) => {
    const values = {
        name,
        permissions,
        status, // Include status in the payload
        organization_id
    }
    let response = await axios.patch(`${baseURL}/api/roles/${id}`, values)
    return response.data
};
// Status Filter Categories
export const get_all_filters_status_roles = async (status) => {
    const response = await axios.post(baseURL + '/api/filter-role-by-status', {
        status: status,
    });
    return response.data;
};
// Active Categories
export const get_all_active_roles = async () => {
    const response = await axios.post(baseURL + '/api/filter-role-by-status', {
        status: 1,
    });
    return response.data;
};