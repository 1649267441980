/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { permissionCheck } from '../../../../../app/helper-functions/PermissionCheck'
import { useAuth } from '../../../../../app/modules/auth'
var category_create, category_activity_log, category_view
var subcategory_create, subcategory_activity_log, subcategory_view
var location_create, location_activity_log, location_view
var people_create, people_activity_log, people_view
var appointment_activity_log, appointment_view
var user_create, user_activity_log, user_view
var role_create, role_activity_log, role_view
var generalsettings_view

const SidebarMenuMain = () => {
  const intl = useIntl()
  category_create = permissionCheck('category-create')
  category_activity_log = permissionCheck('category-activity-log')
  category_view = permissionCheck('category-view')

  subcategory_create = permissionCheck('subcategory-create')
  subcategory_activity_log = permissionCheck('subcategory-activity-log')
  subcategory_view = permissionCheck('subcategory-view')

  location_create = permissionCheck('location-create')
  location_activity_log = permissionCheck('location-activity-log')
  location_view = permissionCheck('location-view')

  appointment_activity_log = permissionCheck('appointment-activity-log')
  appointment_view = permissionCheck('appointment-view')

  people_create = permissionCheck('people-create')
  people_activity_log = permissionCheck('people-activity-log')
  people_view = permissionCheck('people-view')

  user_create = permissionCheck('user-create')
  user_activity_log = permissionCheck('user-activity-log')
  user_view = permissionCheck('user-view')

  role_create = permissionCheck('role-create')
  role_activity_log = permissionCheck('role-activity-log')
  role_view = permissionCheck('role-view')

  generalsettings_view = permissionCheck('generatsettings-view')
  const { auth } = useAuth();
  const isPeople = auth?.user?.is_people;
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='abstract-28'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      {/* categories */}
      {category_activity_log || category_create || category_view || subcategory_activity_log || subcategory_create || subcategory_view ? (
        <SidebarMenuItemWithSub
          to='/categories'
          title='Categories'
          fontIcon='bi-archive'
          icon='category'
        >
          {category_create && <SidebarMenuItem to='/categories/add-category' title='Add Category' hasBullet={true} />}
          {category_view && <SidebarMenuItem to='/categories/categories-list' title='List of Categories' hasBullet={true} />}
          {category_activity_log && <SidebarMenuItem to='/categories/all-categories-logs' title='Category Logs' hasBullet={true} />}
          {subcategory_create && <SidebarMenuItem to='/categories/add-subcategory' title='Add Subcategory' hasBullet={true} />}
          {subcategory_view && <SidebarMenuItem to='/categories/subcategories-list' title='List of Subcategories' hasBullet={true} />}
          {subcategory_activity_log && <SidebarMenuItem to='/categories/all-subcategories-logs' title='Subcategory Logs' hasBullet={true} />}
        </SidebarMenuItemWithSub>
      ) : null}
      {/* locations */}
      {location_activity_log || location_create || location_view ? (
        <SidebarMenuItemWithSub
          to='/locations'
          title='Locations / Platforms'
          fontIcon='bi-archive'
          icon='geolocation'
        >
          {location_create && <SidebarMenuItem to='/locations/add-location' title='Add Location / Platform' hasBullet={true} />}
          {location_view && <SidebarMenuItem to='/locations/locations-list' title='List of Locations / Platforms' hasBullet={true} />}
          {location_activity_log && <SidebarMenuItem to='/locations/all-locations-logs' title='Logs' hasBullet={true} />}
        </SidebarMenuItemWithSub>
      ) : null}
      {/* people */}
      {people_activity_log || people_create || people_view ? (
        <SidebarMenuItemWithSub
          to='/people'
          title='People'
          fontIcon='bi-archive'
          icon='people'
        >
          {people_create && <SidebarMenuItem to='/people/add-person' title='Add Person' hasBullet={true} />}
          {people_view && <SidebarMenuItem to='/people/people-list' title='List of People' hasBullet={true} />}
          {people_activity_log && <SidebarMenuItem to='/people/all-people-logs' title='Logs' hasBullet={true} />}
        </SidebarMenuItemWithSub>
      ) : null}
      {/* appointments */}
      {appointment_activity_log || appointment_view || isPeople ? (
        <SidebarMenuItemWithSub
          to='/appointments'
          title='Appointments'
          fontIcon='bi-archive'
          icon='calendar-tick'
        >
          {appointment_view && <SidebarMenuItem to='/appointments/appointments-list' title='List of Appointments ' hasBullet={true} />}
          {isPeople && <SidebarMenuItem to='/appointments/people-appointments-list' title='List of Appointments ' hasBullet={true} />}
          {appointment_activity_log && <SidebarMenuItem to='/appointments/all-appointments-logs' title='Logs ' hasBullet={true} />}
        </SidebarMenuItemWithSub>
      ) : null}
      {/* User Management*/}
      {user_activity_log || user_create || user_view || role_activity_log || role_create || role_view ? (
        <SidebarMenuItemWithSub
        to='/user-management'
        title='User Management'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        {role_activity_log || role_create || role_view ? (
          <SidebarMenuItemWithSub to='/user-management/roles' title='Roles' hasBullet={true}>
            {role_create && <SidebarMenuItem to='/users/roles/add-role' title='Add Role' hasBullet={true} />}
            {role_view && <SidebarMenuItem to='/users/roles/roles-list' title='List of Roles' hasBullet={true} />}
            {role_activity_log && <SidebarMenuItem to='/users/roles/all-roles-logs' title='Logs' hasBullet={true} />}
          </SidebarMenuItemWithSub>
        ) : null}
        {user_activity_log || user_create || user_view ? (
          <SidebarMenuItemWithSub to='/user-management/users' title='Users' hasBullet={true}>
          {user_create && <SidebarMenuItem to='/users/add-user' title='Add User' hasBullet={true} />}
          {user_view && <SidebarMenuItem to='/users/users-list' title='User List' hasBullet={true} />}
          {user_activity_log && <SidebarMenuItem to='/users/all-users-logs' title='Logs' hasBullet={true} />}
        </SidebarMenuItemWithSub>
        ) : null}
        
      </SidebarMenuItemWithSub>
      ): null }
      {generalsettings_view && <SidebarMenuItem icon='setting-4' to='/add-on-settings' title='Add On Settings' />}
      {generalsettings_view && <SidebarMenuItem icon='setting-2' to='/general/add-general-setting' title='General Settings' />}
      <SidebarMenuItem icon='setting-3' to='/organisation/organisation-settings' title='Organisation Settings' />

    </>
  )
}

export { SidebarMenuMain }
