import axios from "axios";
import { config_multipart } from "../../api-helper/api-header-config";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
// Password change
export const changePassword = async (passwordData: any) => {
    const response = await axios.post(`${baseURL}/api/change-password`, passwordData);
    return response.data;
  };
  export const changePeoplePassword = async (passwordData: any) => {
    const response = await axios.post(`${baseURL}/api/people-change-password`, passwordData);
    return response.data;
  };
  export const people_password_reset = async (token, email, password, password_confirmation) => {
    const response = await axios.post(
      `${baseURL}/api/people-reset-password`,
      {
        token: token,
        email: email,
        password: password,
        password_confirmation: password_confirmation
      }
    );
    return response.data;
  };
  // Get Single User Profile
export const get_single_user_profile = async (id) => {
  let response = await axios.get(baseURL + "/api/get-profile-data");
  return response.data;
}
  // Get Single User Profile
  export const get_single_people_profile = async () => {
    let response = await axios.get(baseURL + "/api/get-people-profile-data");
    return response.data;
  }
// Update User Profile
export const update_user_profile = async (id:number,formData:object) => {
  const response = await axios.post(baseURL + "/api/profile-update/" + id, formData,config_multipart)
return response.data;
}
export const update_people_profile = async (formData) => {
  let id: number;
  id = formData.id
  let response = await axios.post(baseURL + "/api/update-people-info/" + id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  return response.data;
}