import axios from "axios";
import secureLocalStorage from "react-secure-storage";
// Add_Location API
export const add_location = async (values: object) => {
  let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/location", values);
  return response.data;
}
// Get_Location API
export const get_all_location = async (values: object) => {
  let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/get-locations", values);
  return response.data;
}
// Delete Location Api
export const delete_location = async (id: number) => {
  let response = await axios.delete(secureLocalStorage.getItem("baseURL") + "/api/location/" + id);
  return response.data;
}
// change status location
export const change_active_status_location = async (id: number) => {
  let response = await axios.post(secureLocalStorage.getItem("baseURL") + "/api/update-status/" + id, {
    model_name: "location",
  });
  return response.data;
}
// Get Location Filter Api
export const get_all_filters_location = async (location, status) => {
  const response = await axios.post(secureLocalStorage.getItem("baseURL") + '/api/get-locations', {
    type: location,
    status: status,
  });
  return response.data;
};
// Get all virtual platforms
export const get_all_virtual_platforms = async () => {
  const response = await axios.post(secureLocalStorage.getItem("baseURL") + '/api/get-locations', {
    type: "virtual",
    status: 1,
  });
  return response.data;
};
// Get all virtual platforms
export const get_all_meeting_locations = async () => {
  const response = await axios.post(secureLocalStorage.getItem("baseURL") + '/api/get-locations', {
    type: "meeting_at_office",
    status: 1,
  });
  return response.data;
};
// Update Location Api
export const update_location = async (id: number, type: string, platform: string, status: number) => {
  let response = await axios.patch(secureLocalStorage.getItem("baseURL") + "/api/location/" + id, {
    type: type,
    platform_name: platform,
    status: status
  });
  return response.data;
}
// Get Single Location Logs by ID
export const get_single_location_logs = async (id: number) => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/location-activity-log/" + id);
  return response.data;
};
// Get All Subcategory Logs by ID
export const get_all_locations_logs = async () => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/location-activity-logs");
  return response.data;
};
