import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { IUpdatePassword, updatePassword } from "./SettingsModel";
import { changePassword, changePeoplePassword } from "../api/accountsApi";
function AccountSetting() {
  const passwordFormValidationSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
    new_password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("New Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
        "New Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
confirm_password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Confirm Password is required")
      .oneOf([Yup.ref("new_password")], "New and Confirm Password must match"),
  });
const [passwordUpdateData, setPasswordUpdateData] = useState<IUpdatePassword>(updatePassword);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isPeople =
    JSON.parse(localStorage.getItem('kt-auth-react-v') || '')?.user?.is_people || null
  const formik = useFormik<IUpdatePassword>({
    initialValues: {
      ...passwordUpdateData,
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: (values) => {
      mutation.mutate(values);
    },
  });
  const mutation = useMutation(
    async (values: IUpdatePassword) => isPeople? changePeoplePassword(values) : changePassword(values),
    {
      onSuccess: (data) => {
        toast.success(data.message, {
          theme: "colored",
        });
        formik.resetForm();
      },
      onError: (error: any) => {
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      },
    }
  );
  return (
    <div className="card mb-5 mb-xl-10">
      {/* Password Section */}
      <div
        className="card-header border-0 "
        role="button"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Change Password</h3>
        </div>
      </div>
      <div id="kt_account_profile_details">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required"> Password</span>
              </label>

              <div className="col-lg-8 fv-row">
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Current Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="password"
                  />
                  <span
                    className={`input-group-text ${showPassword
                        ? "text-primary cursor-pointer border-0"
                        : "text-muted cursor-pointer border-0"
                      }`}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <i className="bi bi-eye-slash-fill"></i>
                    ) : (
                      <i className="bi bi-eye-fill"></i>
                    )}
                  </span>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required"> New Password</span>
              </label>
              <div className="col-lg-8 fv-row">
                <div className="input-group">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    className="form-control form-control-lg form-control-solid"
                    placeholder="New Password"
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="new_password"
                  />
                  <span
                    className={`input-group-text ${showNewPassword
                        ? "text-primary cursor-pointer border-0"
                        : "text-muted cursor-pointer border-0"
                      }`}
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? (
                      <i className="bi bi-eye-slash-fill"></i>
                    ) : (
                      <i className="bi bi-eye-fill"></i>
                    )}
                  </span>
                </div>
                {formik.touched.new_password && formik.errors.new_password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.new_password}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Confirm Password</span>
              </label>
              <div className="col-lg-8 fv-row">
                <div className="input-group">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Confirm Password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="confirm_password"
                  />
                  <span
                    className={`input-group-text ${showConfirmPassword
                        ? "text-primary cursor-pointer border-0"
                        : "text-muted cursor-pointer border-0"
                      }`}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <i className="bi bi-eye-slash-fill"></i>
                    ) : (
                      <i className="bi bi-eye-fill"></i>
                    )}
                  </span>
                </div>
                {formik.touched.confirm_password &&
                  formik.errors.confirm_password && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.confirm_password}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={mutation.isLoading}
            >
              {!mutation.isLoading ? (
                "Save Changes"
              ) : (
                <span
                  className="indicator-progress d-block"
                >
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccountSetting