import moment from 'moment';

export const formatDateWithTime = (date: string) => {
    return moment(date).format('DD/MM/YYYY h:mma')
};

export const formatDateWithoutTime = (date: string) => {
    return moment(date).format('DD/MM/YYYY')
};
export const formatDate = (date: string) => {
    return moment(date).format('YYYY/MM/DD')
};
export const formatDateFlatpickr = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};