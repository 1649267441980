import secureLocalStorage from 'react-secure-storage'
import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const PERMISSION_LOCAL_STORAGE_KEY = 'user_permissions'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}
const getPermission = () => {
  if (!secureLocalStorage) {
    return
  }

  const lsPermission : any= secureLocalStorage.getItem(PERMISSION_LOCAL_STORAGE_KEY)
  if (!lsPermission) {
    return
  }

  try {
    const permission: any = JSON.parse(lsPermission) as any
    if (permission) {
      // You can easily check auth_token expiration also
      return permission
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}
const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}
const setPermission = (permission) => {
  if (!secureLocalStorage) {
    return
  }

  try {
    const lsPermission = JSON.stringify(permission)
    secureLocalStorage.setItem(PERMISSION_LOCAL_STORAGE_KEY, lsPermission)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}
const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    // localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}
const removePermission = () => {
  if (!secureLocalStorage) {
    return
  }

  try {
    // secureLocalStorage.removeItem(PERMISSION_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}
export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      const permission = getPermission()
      if (auth && auth.access_token ) {
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, setPermission, getPermission, removePermission, PERMISSION_LOCAL_STORAGE_KEY}
