import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query';
import { get_all_active_categories } from '../../categories/api/categoriesApi';
import Select from 'react-select';
import * as Yup from 'yup';
import { get_all_active_subcategories, get_all_subcategories_against_category } from '../../subcategories/api/subcategoriesApi';
import { get_all_meeting_locations, get_all_virtual_platforms } from '../../locations/api/locationsApi';
import { useFormik } from 'formik';
import { addPerson, allLocationType, initialValuesPerson as initialValues } from '../../../modules/accounts/components/settings/SettingsModel';
import { add_person } from '../api/peopleApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCountries } from '../../../helper-functions/Countries';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';

interface Category {
  id: number;
  title: string;
};
interface Subcategory {
  id: number;
  title: string;
  categories_id: number | undefined;
  categories_title: string | undefined;
};

const numberRegex = /^[+0-9]+$/;
const peopleSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  address_line_1: Yup.string().required('Address 1 is required'),
  town: Yup.string().required('Town is required'),
  city: Yup.string().required('City is required'),
  postal_code: Yup.string().required('Postal Code is required'),
  country: Yup.string().required('Country is required'),
  email: Yup.string()
    .required('Email is required')
    .matches(/^[A-Za-z0-9._%+-]+@([A-Za-z0-9.-]+\.[A-Za-z]{2,4})$/, 'Invalid email address'),
  phone_number: Yup.string()
    .matches(numberRegex, 'Phone number must be in numeric value'),
  mobile_number: Yup.string()
    .required('Mobile Number is required')
    .matches(numberRegex, 'Mobile Number must be in numeric value'),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  confirm_password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password Confirmation is required")
    .oneOf([Yup.ref("password")], "New and Confirm Password must match"),
});
function AddPeople() {
  const [categories, setCategories] = useState<Category[]>([])
  const [subcategories, setSubcategories] = useState<Subcategory[]>([])
  const [locations, setLocations] = useState<allLocationType[]>([])
  const [platforms, setPlatforms] = useState<allLocationType[]>([])
  const [selectedId, setSelectedid] = useState<Number>()
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [subcategoryValidation, setSubcategoryValidation] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  //getting categories data
  const { data: categoryData } = useQuery('categories', get_all_active_categories, {refetchOnWindowFocus: false})
  useEffect(() => {
    if (categoryData) {
      setCategories(categoryData.data as Category[])
    }
  }, [categoryData]);
  //getting subcategories data based on category
  const { data: subcategoryData } = useQuery(['subcategory', selectedId], () => get_all_subcategories_against_category(selectedId), {
    refetchOnWindowFocus: false,
    enabled: !!selectedId,
    onSuccess: () => {
    }
  });
  useEffect(() => {
    if (subcategoryData && selectedId) {
      setSubcategories(prevSubcategories => [
        ...prevSubcategories,
        ...subcategoryData.data as Subcategory[],
      ]);
    }
  }, [subcategoryData, selectedId]);
  const { data: platformData } = useQuery('platforms', get_all_virtual_platforms, {refetchOnWindowFocus: false})
  useEffect(() => {
    if (platformData) {
      setPlatforms(platformData.data as allLocationType[])
    }
  }, [platformData])
  // getting all locations
  const { data: locationData } = useQuery('locations', get_all_meeting_locations, {refetchOnWindowFocus: false})
  useEffect(() => {
    if (locationData) {
      setLocations(locationData.data as allLocationType[])
    }
  }, [locationData])
  // function to change the subcategories list
  const handleCategoryChage = (selectedOptions) => {
    selectedOptions.map((option) => {
      setSelectedid(option?.value)
    })
  }
  //mutation to add person
  const mutation = useMutation(add_person, {
    onSettled: (data) => {
      toast.success(data.message, {
        position: 'top-right',
        autoClose: 3000,
      });
      navigate('/people/people-list');
    },
    onError: (error: any) => {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 2000,
        })
      }
    },
  });
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue('picture', file);
  };
  const formik = useFormik<addPerson>({
    initialValues,
    validationSchema: peopleSchema,
    onSubmit: (values, action) => {
      mutation.mutate({
        title: values.title,
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        address_line_1: values.address_line_1,
        address_line_2: values.address_line_2,
        town: values.town,
        city: values.city,
        postal_code: values.postal_code,
        country: values.country,
        mobile_number: values.mobile_number,
        phone_number: values.phone_number,
        picture: values.picture,
        other_information: values.other_information,
        password: values.password,
        confirm_password: values.confirm_password,
        email: values.email,
        appointment_rate: values.appointment_rate,
        status: values.status,
        sub_categories: values.sub_categories?.map((subcategory) => {
          return {
            sub_category_id: subcategory.value,
          };
        }),
        people_locations: values.people_locations.map((location) => {
          return {
            location_id: location.value,
          };
        }),
        people_platforms: values.people_platforms.map((platform) => {
          return {
            location_id: platform.value,
          };
        }),
      });
    },
  });
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Person</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Category</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <Select
                isMulti
                placeholder="Select Categories... "
                options={Array.isArray(categories) ? categories.map((category) => ({
                  value: category.id,
                  label: category.title,
                })) : []}                
                className="basic-multi-select form-select-lg p-2 bg-light text-black"
                classNamePrefix="select border-light fw-bold"
                onChange={(selectedOptions) => {
                  handleCategoryChage(selectedOptions)
                }}
              />
            </div>
          </div>
          <div className='row mb-6'>
            {/* department */}
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Subcategory</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <Select
                {...formik.getFieldProps('sub_categories')}
                isMulti
                placeholder="Select Subcategories..."
                options={subcategories?.map((subcategory) => ({
                  value: subcategory.id,
                  label: subcategory.title,
                }))}
                className="basic-multi-select form-select-lg p-2 bg-light text-black"
                classNamePrefix="select border-light fw-bold"
                onChange={(selectedOptions) => {
                  if (!selectedOptions || selectedOptions.length === 0) {
                    setSubcategoryValidation("Subcategory is required")
                  } else {
                    setSubcategoryValidation("")
                  }
                  formik.setFieldValue('sub_categories', selectedOptions);
                }}
              />
              {subcategoryValidation && (
                <div className='text-danger'>{subcategoryValidation}</div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Title</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                {...formik.getFieldProps('title')}
                value={formik.values.title}
                onChange={(e) => formik.setFieldValue('title', e.target.value)}
                onBlur={formik.handleBlur}
              >
                <option value=''>Select a Title...</option>
                <option value='Mr.'>Mr.</option>
                <option value='Mrs.'>Mrs.</option>
                <option value='Ms.'>Ms.</option>
              </select>
              {formik.touched.title && formik.errors.title && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.title}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>First Name</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='First Name'
                {...formik.getFieldProps('first_name')}
                onChange={formik.handleChange}
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.first_name}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Middle Name</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Middle Name'
                {...formik.getFieldProps('middle_name')}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Last Name</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Last Name'
                {...formik.getFieldProps('last_name')}
                onChange={formik.handleChange}
              />
              {formik.touched.last_name && formik.errors.last_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.last_name}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Address Line 1</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Address Line 1'
                {...formik.getFieldProps('address_line_1')}
                onChange={formik.handleChange}
              />
              {formik.touched.address_line_1 && formik.errors.address_line_1 && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address_line_1}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Address Line 2</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Address Line 2'
                {...formik.getFieldProps('address_line_2')}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Town</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Town'
                {...formik.getFieldProps('town')}
                onChange={formik.handleChange}
              />
              {formik.touched.town && formik.errors.town && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.town}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>City</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='City'
                {...formik.getFieldProps('city')}
                onChange={formik.handleChange}
              />
              {formik.touched.city && formik.errors.city && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.city}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Country </label>
            <div className='col-lg-8 fv-row'>
              <select
                className='form-select form-select-lg form-select-solid'
                {...formik.getFieldProps('country')}
                onChange={formik.handleChange}
              >
                <option value=''>Select a country...</option>
                {getCountries().map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              {formik.touched.country && formik.errors.country && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.country}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Postal Code</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder='Postal Code'
                {...formik.getFieldProps('postal_code')}
                onChange={formik.handleChange}
              />
              {formik.touched.postal_code && formik.errors.postal_code && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.postal_code}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Mobile Number</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='tel'
                className='form-control form-control-lg form-control-solid'
                placeholder='Mobile Number'
                {...formik.getFieldProps('mobile_number')}
                onChange={formik.handleChange}
              />
              {formik.touched.mobile_number && formik.errors.mobile_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.mobile_number}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Phone Number</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='tel'
                className='form-control form-control-lg form-control-solid'
                placeholder='Phone Number'
                {...formik.getFieldProps('phone_number')}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Other Information</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='textarea'
                className='form-control form-control-lg form-control-solid'
                placeholder='Other Information'
                {...formik.getFieldProps('other_information')}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6 required'>Email</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='email'
                className='form-control form-control-lg form-control-solid'
                placeholder='Email'
                {...formik.getFieldProps('email')}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>New Password</label>
            <div className='col-lg-8 fv-row'>
              <div className='input-group'>
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='New Password'
                  {...formik.getFieldProps('password')}
                />
                <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? 'Hide' : 'Show'}
                </button>
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.password}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Confirm Password</label>
            <div className='col-lg-8 fv-row'>
              <div className='input-group'>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Confirm Password'
                  {...formik.getFieldProps('confirm_password')}
                />
                <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? 'Hide' : 'Show'}
                </button>
              </div>
              {formik.touched.confirm_password && formik.errors.confirm_password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.confirm_password}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Picture</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='file'
                className='form-control form-control-lg form-control-solid'
                onChange={(e) => handleImageChange(e)}
                name='picture'
              />
              <div className='text-danger mt-2'>
                Recommended size 200 x 200 px. jpg, jpeg, or png only
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Appointment Rate ({getSettingValue('currency_symbol')})</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder='Appointment Rate'
                {...formik.getFieldProps('appointment_rate')}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>Availale Platforms</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <Select
                {...formik.getFieldProps('people_platforms')}
                isMulti
                placeholder="Select platforms... "
                options={platforms?.map((platform) => ({
                  value: platform.id,
                  label: platform.platform_name,
                }))}
                className="basic-multi-select form-select-lg p-2 bg-light text-black"
                classNamePrefix="select border-light fw-bold"
                onChange={(selectedOptions) => {
                  formik.setFieldValue('people_platforms', selectedOptions);
                }}
              />
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>Available Locations</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <Select
                {...formik.getFieldProps('people_locations')}
                isMulti
                placeholder="Select Locations... "
                options={locations?.map((location) => ({
                  value: location.id,
                  label: location.platform_name,
                }))}
                className="basic-multi-select form-select-lg p-2 bg-light text-black"
                classNamePrefix="select border-light fw-bold"
                onChange={(selectedOptions) => {
                  formik.setFieldValue('people_locations', selectedOptions);
                }}
              />
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>Status</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                {...formik.getFieldProps('status')}
              >
                <option value='1'>Active</option>
                <option value='0'>Inactive</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button
          data-toggle='tooltip'
          data-placement='bottom'
          title='Add New People'
          type='button'
          className='btn btn-primary'
          disabled={loading}
          onClick={() => formik.handleSubmit()}
        >
          {!loading && 'Add Person'}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default AddPeople