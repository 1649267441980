import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { add_add_on_settings, get_add_on_settings } from '../api/addonSettingsApi';
import { addOnSettings, initialValuesAddOnSettings } from '../../../modules/accounts/components/settings/SettingsModel';
import secureLocalStorage from 'react-secure-storage';
function AddonSettings() {
  const [settingData, setSettingData] = useState<any>("");
  const [headingColor, setHeadingColor] = useState<string>("");
  const [formTitle, setFormTitle] = useState<string>("");
  const [organizationName, setOrganizationName] = useState<string>("");
  const [appointmentTypeTitle, setAppointmentTypeTitle] = useState<string>("");
  const [LocationTitle, setLocationTitle] = useState<string>("");
  const [previousStepColor, setPreviousStepColor] = useState<string>("");
  const [nextStepColor, setNextStepColor] = useState<string>("");
  const [backgroundColor, setBackgroundColor] = useState<string>("");
  const [buttonColor, setButtonColor] = useState<string>("");
  const [buttonTextColor, setButtonTextColor] = useState<string>();
  const [buttonHoverColor, setButtonHoverColor] = useState<string>("");
  const [buttonHoverTextColor, setButtonHoverTextColor] = useState<string>("");
  const [inputLabelColor, setInputLabelColor] = useState<string>("");
  const [inputColor, setInputColor] = useState<string>("");
  const [categoryTitle, setCategoryTitle] = useState<string>("");
  const [subcategoryTitle, setSubcategoryTitle] = useState<string>("");
  const [textcolor, setTextColor] = useState<string>("");
  const [cardsBackgroundColor, setCardsbackgroundColor] = useState<string>("");
  const [selectedCardColor, setSelectedCardColor] = useState<string>("");
  const [selectedDateColor, setSelectedDateColor] = useState<string>("");
  const [calendarHeadColor, setCalendarHeadColor] = useState<string>("");
  const [currentDateColor, setCurrentDateColor] = useState<string>("");
  const [dateTextColor, setDateTextColor] = useState<string>("");
  const [calendarBcakgroundColor, setCalendarBackgroundColor] = useState<string>("");
  const [slotDifference, setSlotDifference] = useState<string>("");
  const [shiftStartTime, setShiftStartTime] = useState<string>("");
  const [shiftEndTime, setShiftEndTime] = useState<string>("");
  const [slotColor, setSlotColor] = useState<string>("");
  const [selectedSlotColor, setSelectedSlotColor] = useState<string>("");
  const [pastSlotColor, setpastSlotColor] = useState<string>("");
  const [bookedSlotColor, setBookedSlotColor] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const { data, isLoading } = useQuery('addonSettings', get_add_on_settings, { refetchOnWindowFocus: false });
  const scriptSrc = process.env.REACT_APP_ADDON_JS_URL; // Script URL from env
  const htmlElement = process.env.REACT_APP_ADDON_HTML_TAG; // HTML from env
  const [script1, setScript1] = useState<string>("")
  const [script2, setScript2] = useState<string>("")
  useEffect(() => {
    if (data) {
      const settingValue = JSON.parse(data.data[0].setting_value);
      setSettingData(settingValue);
    }
  }, [data]);
  useEffect(() => {
    setScript2(`<script> const baseURL = ${secureLocalStorage.getItem('baseURL')} </script>`)
    setScript1(`<script src=${scriptSrc}" referrerpolicy="origin"></script>`)
  }, []);
  const mutation = useMutation(add_add_on_settings, {
    onSuccess: (data) => {
      toast.success(data.message, {
        position: "top-right",
        autoClose: 3000,
      });
      setLoading(false)
    },
    onError: (error: any) => {
      setLoading(false)
    }
  });
  const addonSettingValue = {
    heading_color: headingColor ? headingColor : settingData?.heading_color,
    previous_step_color: previousStepColor ? previousStepColor : settingData?.previous_step_color,
    next_step_color: nextStepColor ? nextStepColor : settingData?.next_step_color,
    background_color: backgroundColor ? backgroundColor : settingData?.background_color,
    button_color: buttonColor ? buttonColor : settingData?.button_color,
    button_text_color: buttonTextColor ? buttonTextColor : settingData?.button_text_color,
    button_hover_color: buttonHoverColor ? buttonHoverColor : settingData?.button_hover_color,
    button_hover_text_color: buttonHoverTextColor ? buttonHoverTextColor : settingData?.button_hover_text_color,
    input_label_color: inputLabelColor ? inputLabelColor : settingData?.input_label_color,
    input_color: inputColor ? inputColor : settingData?.input_color,
    category_title: categoryTitle ? categoryTitle : settingData?.category_title,
    subcategory_title: subcategoryTitle ? subcategoryTitle : settingData?.subcategory_title,
    text_color: textcolor ? textcolor : settingData?.text_color,
    cards_background_color: cardsBackgroundColor ? cardsBackgroundColor : settingData?.cards_background_color,
    calendar_background_color: calendarBcakgroundColor ? calendarBcakgroundColor : settingData?.calendar_background_color,
    slot_difference: slotDifference ? slotDifference : settingData?.slot_difference,
    shift_start_time: shiftStartTime ? shiftStartTime : settingData?.shift_start_time,
    shift_end_time: shiftEndTime ? shiftEndTime : settingData?.shift_end_time,
    selected_slot_color: selectedSlotColor ? selectedSlotColor : settingData?.selected_slot_color,
    past_slot_color: pastSlotColor ? pastSlotColor : settingData?.past_slot_color,
    booked_slot_color: bookedSlotColor ? bookedSlotColor : settingData?.booked_slot_color,
    form_title: formTitle ? formTitle : settingData?.form_title,
    organization_name: organizationName ? organizationName : settingData?.organization_name,
    appointment_type_title: appointmentTypeTitle ? appointmentTypeTitle : settingData?.appointment_type_title,
    location_title: LocationTitle ? LocationTitle : settingData?.location_title,
    selected_card_color: selectedCardColor ? selectedCardColor : settingData?.selected_card_color,
    calendar_head_color: calendarHeadColor ? calendarHeadColor : settingData?.calendar_head_color,
    selected_date_color: selectedDateColor ? selectedDateColor : settingData?.selected_date_color,
    current_date_color: currentDateColor ? currentDateColor : settingData?.current_date_color,
    current_date_text_color: dateTextColor ? dateTextColor : settingData?.current_date_text_color,
    slot_color: slotColor ? slotColor : settingData?.slot_color
  };
  const formik = useFormik<addOnSettings>({
    initialValues: initialValuesAddOnSettings,
    onSubmit: (values) => {
      console.log(JSON.stringify(addonSettingValue))
      setLoading(true)
      mutation.mutate({
        "setting_value": JSON.stringify(addonSettingValue),
      });
    },
  }
  )
  if (isLoading) {
    return (
      <div className='text-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  };
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Addon Settings</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Organization Name</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={organizationName ? organizationName : settingData.organization_name}
                  onChange={(e) => {
                    setOrganizationName(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <label className="col-lg-4 ml-4 col-form-label fw-bold fs-6">Script tags:</label>
              <div className="col-lg-8 fv-row">
                <div>
                  <div>
                    {/* Display the Script string */}
                    <pre
                      style={{
                        backgroundColor: '#f4f4f4',
                        padding: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd',
                        fontFamily: 'monospace',
                        fontSize: '14px',
                        overflowX: 'auto',
                        marginBottom: '10px',
                      }}
                    >
                      {script2}<br/>{script1}
                    </pre>

                    {/* Button to copy the Script string */}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard.writeText(script1 + script2);
                        toast.success("Copied Script tags", {
                          position: "top-right",
                          autoClose: 3000,
                        });
                      }}
                      style={{
                        padding: '8px 16px',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '14px',
                      }}
                    >
                      Copy Script Tags
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <label className="col-lg-4 ml-4 col-form-label fw-bold fs-6">HTML tag:</label>
              <div className="col-lg-8 fv-row">
                {/* Check if htmlElement exists */}
                {htmlElement && (
                  <div>
                    <div>
                      {/* Display the HTML string */}
                      <pre
                        style={{
                          backgroundColor: '#f4f4f4',
                          padding: '10px',
                          borderRadius: '4px',
                          border: '1px solid #ddd',
                          fontFamily: 'monospace',
                          fontSize: '14px',
                          overflowX: 'auto',
                          marginBottom: '10px',
                        }}
                      >
                        {htmlElement}
                      </pre>

                      {/* Button to copy the HTML string */}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          navigator.clipboard.writeText(htmlElement);
                          toast.success("Copied HTML element", {
                            position: "top-right",
                            autoClose: 3000,
                          });
                        }}
                        style={{
                          padding: '8px 16px',
                          backgroundColor: '#007bff',
                          color: '#fff',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          fontSize: '14px',
                        }}
                      >
                        Copy HTML Element
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Form Title</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={formTitle ? formTitle : settingData.form_title}
                  onChange={(e) => {
                    setFormTitle(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Appointment Type Title</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={appointmentTypeTitle ? appointmentTypeTitle : settingData.appointment_type_title}
                  onChange={(e) => {
                    setAppointmentTypeTitle(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Location/Platform Title</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={LocationTitle ? LocationTitle : settingData.location_title}
                  onChange={(e) => {
                    setLocationTitle(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Category Title</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={categoryTitle ? categoryTitle : settingData.category_title}
                  onChange={(e) => {
                    setCategoryTitle(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Subcategory Title</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={subcategoryTitle ? subcategoryTitle : settingData.subcategory_title}
                  onChange={(e) => {
                    setSubcategoryTitle(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Slot Difference (min)</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  value={slotDifference ? slotDifference : settingData.slot_difference}
                  onChange={(e) => {
                    setSlotDifference(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Shift Start Time</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  value={shiftStartTime ? shiftStartTime : settingData.shift_start_time}
                  onChange={(e) => {
                    setShiftStartTime(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Shift End Time</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  value={shiftEndTime ? shiftEndTime : settingData.shift_end_time}
                  onChange={(e) => {
                    setShiftEndTime(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Heading Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={headingColor ? headingColor : settingData.heading_color}
                  onChange={(e) => {
                    setHeadingColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={headingColor ? headingColor : settingData.heading_color}
                  onChange={(e) => {
                    setHeadingColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Previous Step Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={previousStepColor ? previousStepColor : settingData.previous_step_color}
                  onChange={(e) => {
                    setPreviousStepColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={previousStepColor ? previousStepColor : settingData.previous_step_color}
                  onChange={(e) => {
                    setPreviousStepColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Next Step Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={nextStepColor ? nextStepColor : settingData.next_step_color}
                  onChange={(e) => {
                    setNextStepColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={nextStepColor ? nextStepColor : settingData.next_step_color}
                  onChange={(e) => {
                    setNextStepColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Background Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={backgroundColor ? backgroundColor : settingData.background_color}
                  onChange={(e) => {
                    setBackgroundColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={backgroundColor ? backgroundColor : settingData.background_color}
                  onChange={(e) => {
                    setBackgroundColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Button Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={buttonColor ? buttonColor : settingData.button_color}
                  onChange={(e) => {
                    setButtonColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={buttonColor ? buttonColor : settingData.button_color}
                  onChange={(e) => {
                    setButtonColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Button Text Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={buttonTextColor ? buttonTextColor : settingData.button_text_color}
                  onChange={(e) => {
                    setButtonTextColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={buttonTextColor ? buttonTextColor : settingData.button_text_color}
                  onChange={(e) => {
                    setButtonTextColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Button Hover Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={buttonHoverColor ? buttonHoverColor : settingData.button_hover_color}
                  onChange={(e) => {
                    setButtonHoverColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={buttonHoverColor ? buttonHoverColor : settingData.button_hover_color}
                  onChange={(e) => {
                    setButtonHoverColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Button Hover Text Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={buttonHoverTextColor ? buttonHoverTextColor : settingData.button_hover_text_color}
                  onChange={(e) => {
                    setButtonHoverTextColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={buttonHoverTextColor ? buttonHoverTextColor : settingData.button_hover_text_color}
                  onChange={(e) => {
                    setButtonHoverTextColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Dropdown Menu Field Title Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={inputLabelColor ? inputLabelColor : settingData.input_label_color}
                  onChange={(e) => {
                    setInputLabelColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={inputLabelColor ? inputLabelColor : settingData.input_label_color}
                  onChange={(e) => {
                    setInputLabelColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Dropdown Menu Field Text Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={inputColor ? inputColor : settingData.input_color}
                  onChange={(e) => {
                    setInputColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={inputColor ? inputColor : settingData.input_color}
                  onChange={(e) => {
                    setInputColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>People Card Text Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={textcolor ? textcolor : settingData.text_color}
                  onChange={(e) => {
                    setTextColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={textcolor ? textcolor : settingData.text_color}
                  onChange={(e) => {
                    setTextColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Cards Background Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={cardsBackgroundColor ? cardsBackgroundColor : settingData.cards_background_color}
                  onChange={(e) => {
                    setCardsbackgroundColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={cardsBackgroundColor ? cardsBackgroundColor : settingData.cards_background_color}
                  onChange={(e) => {
                    setCardsbackgroundColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Selected Card Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={selectedCardColor ? selectedCardColor : settingData.selected_card_color}
                  onChange={(e) => {
                    setSelectedCardColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={selectedCardColor ? selectedCardColor : settingData.selected_card_color}
                  onChange={(e) => {
                    setSelectedCardColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Calendar Background Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={calendarBcakgroundColor ? calendarBcakgroundColor : settingData.calendar_background_color}
                  onChange={(e) => {
                    setCalendarBackgroundColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={calendarBcakgroundColor ? calendarBcakgroundColor : settingData.calendar_background_color}
                  onChange={(e) => {
                    setCalendarBackgroundColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Calendar Head Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={calendarHeadColor ? calendarHeadColor : settingData.calendar_head_color}
                  onChange={(e) => {
                    setCalendarHeadColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={calendarHeadColor ? calendarHeadColor : settingData.calendar_head_color}
                  onChange={(e) => {
                    setCalendarHeadColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Selected Date Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={selectedDateColor ? selectedDateColor : settingData.selected_date_color}
                  onChange={(e) => {
                    setSelectedDateColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={selectedDateColor ? selectedDateColor : settingData.selected_date_color}
                  onChange={(e) => {
                    setSelectedDateColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label fw-bold fs-6'>Current Date Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={currentDateColor ? currentDateColor : settingData.current_date_color}
                  onChange={(e) => {
                    setCurrentDateColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={currentDateColor ? currentDateColor : settingData.current_date_color}
                  onChange={(e) => {
                    setCurrentDateColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label fw-bold fs-6'>Date Text Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={dateTextColor ? dateTextColor : settingData.current_date_text_color}
                  onChange={(e) => {
                    setDateTextColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={dateTextColor ? dateTextColor : settingData.current_date_text_color}
                  onChange={(e) => {
                    setDateTextColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Slot Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={slotColor ? slotColor : settingData.slot_color}
                  onChange={(e) => {
                    setSlotColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={slotColor ? slotColor : settingData.slot_color}
                  onChange={(e) => {
                    setSlotColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Selected Slot Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={selectedSlotColor ? selectedSlotColor : settingData.selected_slot_color}
                  onChange={(e) => {
                    setSelectedSlotColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={selectedSlotColor ? selectedSlotColor : settingData.selected_slot_color}
                  onChange={(e) => {
                    setSelectedSlotColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label  fw-bold fs-6'>Past Slot Colour</label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={pastSlotColor ? pastSlotColor : settingData.past_slot_color}
                  onChange={(e) => {
                    setpastSlotColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={pastSlotColor ? pastSlotColor : settingData.past_slot_color}
                  onChange={(e) => {
                    setpastSlotColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 ml-4 col-form-label fw-bold fs-6'>Booked Slot Colour </label>
              <div className='col-lg-2 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  value={bookedSlotColor ? bookedSlotColor : settingData.booked_slot_color}
                  onChange={(e) => {
                    setBookedSlotColor(e.target.value)
                  }}
                />
              </div>
              <div className='col-lg-6 fv-row'>
                <input
                  type='color'
                  className='form-control form-control-lg form-control-solid'
                  value={bookedSlotColor ? bookedSlotColor : settingData.booked_slot_color}
                  onChange={(e) => {
                    setBookedSlotColor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              data-toggle='tooltip'
              data-placement='bottom'
              title='Save Settings'
              type='submit'
              className='btn btn-primary'
              disabled={loading}
            >
              {!loading && 'Save Settings'}
              {loading && (
                <span className='indicator-progress d-block'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
};
export default AddonSettings;