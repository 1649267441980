import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { get_single_appointment, get_single_people_appointment } from '../api/appointmentsApi';
import { formatDateWithTime } from '../../../helper-functions/DateFunction';
interface Appointment {
  id: number;
  location_id: string;
  location_type: number | undefined;
  subcategory_id: string | undefined;
  subcategory_title: string | undefined;
  people_id: string | undefined;
  people_name: string | undefined;
  appointment_date_time: string;
  full_name: string | undefined;
  phone_number: string | undefined;
  message: string | undefined;
  payment_mode: string | undefined;
  email: string | undefined;
  payment_status: number | undefined;
  payment_date_time: string;
  amount: string | undefined;
  created_at: string | undefined;
  updated_by: string | undefined;
  updated_at: string;

};
function ViewAppointment() {
  const [singleAppointment, setSingleAppointment] = useState<Appointment | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const id = (location.state as { id: number }).id;
  const isPeople =
    JSON.parse(localStorage.getItem('kt-auth-react-v') || '')?.user.is_people || null
  const { data: singleAppointmentData, isLoading: singleAppointmentLoading } = useQuery(
    ['singleAppointment', id], () => isPeople ? get_single_people_appointment(id!) : get_single_appointment(id!), {
      refetchOnWindowFocus: false,
    enabled: !!id,
  }
  );
  useEffect(() => {
    if (singleAppointmentData) {
      setSingleAppointment(singleAppointmentData.data as Appointment);
    }
  }, [singleAppointmentData])
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Appointment Details</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
        >
          <button
            data-toggle="tooltip" data-placement="bottom" title="Navigate to List of Appointments"
            className='btn btn-sm btn-light-dark fs-5'
            onClick={() => navigate('/appointments/appointments-list')}
          >
            List of Appointments
          </button>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          {singleAppointmentLoading ? (
            <tr>
              <td colSpan={6}>
                <div className='text-center'>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
          ) : singleAppointment ? (
            <>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Lacation/Platform</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singleAppointment.location_type}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Purpose of Appointment</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singleAppointment.subcategory_title}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Appointment With</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singleAppointment.people_name}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Appointment Date & Time</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {formatDateWithTime(singleAppointment.appointment_date_time)}
                    </label>
                  </div>
                </div>
              </div>
              <div className="card card-custom card-border">
                <div className="card-header">
                  <h3 className="card-title fw-bolder">Customer's Information</h3>
                </div>
                <div className="card-body">
                  <div className='row'>
                    <div className='form-group col-lg-5'>
                      <div className='row mb-6 '>
                        <label className='col-lg-10 col-form-label fw-bold fs-6  mx-5'>
                          <span>Full Name</span>
                        </label>
                        <div className='col-lg-10 fv-row'>
                          <label className='form-control form-control-lg form-control-solid'>
                            {singleAppointment.full_name}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='form-group col-lg-5'>
                      <div className='row mb-6 '>
                        <label className='col-lg-10 col-form-label fw-bold fs-6  mx-5'>
                          <span>Email</span>
                        </label>
                        <div className='col-lg-10 fv-row'>
                          <label className='form-control form-control-lg form-control-solid'>
                            {singleAppointment.email}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='form-group col-lg-6'>
                    <div className='row mb-6 '>
                      <label className='col-lg-10 col-form-label fw-bold fs-6  mx-5'>
                        <span>Contact Number</span>
                      </label>
                      <div className='col-lg-10 fv-row'>
                        <label className='form-control form-control-lg form-control-solid'>
                          {singleAppointment.phone_number}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='row mb-6 '>
                      <label className='col-lg-10 col-form-label fw-bold fs-6  mx-5'>
                        <span>Message</span>
                      </label>
                      <div className='col-lg-9 fv-row'>
                        <textarea
                          className='form-control form-control-lg form-control-solid '
                          value={singleAppointment.message}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className='mb-6'>
                    <h3 className="card-title fw-bolder">Payment Details</h3>
                  </div>
                  <div className='row'>
                    <div className='form-group col-lg-5'>
                      <div className='row mb-6 '>
                        <label className='col-lg-10 col-form-label fw-bold fs-6  mx-5'>
                          <span>Payment Method</span>
                        </label>
                        <div className='col-lg-10 fv-row'>
                          <label className='form-control form-control-lg form-control-solid'>
                            {singleAppointment.payment_mode}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='form-group col-lg-5'>
                      <div className='row mb-6 '>
                        <label className='col-lg-10 col-form-label fw-bold fs-6  mx-5'>
                          <span>Amount</span>
                        </label>
                        <div className='col-lg-10 fv-row'>
                          <label className='form-control form-control-lg form-control-solid'>
                            {singleAppointment.amount}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='form-group col-lg-5'>
                      <div className='row mb-6 '>
                        <label className='col-lg-10 col-form-label fw-bold fs-6  mx-5'>
                          <span>Payment Statuus</span>
                        </label>
                        <div className='col-lg-10 fv-row'>
                          {singleAppointment.payment_status === 1 ? (
                            <label className='form-control form-control-lg form-control-solid'>
                              Paid
                            </label>
                          ) : (
                            <label className='form-control form-control-lg form-control-solid'>
                              Pending
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='form-group col-lg-5'>
                      <div className='row mb-6 '>
                        <label className='col-lg-10 col-form-label fw-bold fs-6  mx-5'>
                          <span>Payment Date & Time</span>
                        </label>
                        <div className='col-lg-10 fv-row'>
                          <label className='form-control form-control-lg form-control-solid'>
                            {formatDateWithTime(singleAppointment.payment_date_time)}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ViewAppointment